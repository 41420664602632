/* eslint-disable camelcase */
const defaultState = () => ({
  sessions: [],
  totalSessions: 0,
  currentSession: null,
  prerequisiteAssessments: [],
  currentAssessment: {
    comments: [],
  },
})
export const state = defaultState()

export const mutations = {
  RESET_SESSIONS(state) {
    state.sessions = []
    state.totalSessions = 0
  },
  RESET_CURRENT_SESSION(state) {
    state.currentSession = null
  },
  SET_SESSIONS(state, sessions) {
    state.sessions = sessions
  },
  SET_TOTAL_SESSIONS(state, totalSessions) {
    state.totalSessions = totalSessions
  },
  SET_CURRENT_SESSION(state, session) {
    state.currentSession = session
  },
  SET_CURRENT_LESSON_ASSESSMENT_COMMENTS(state, comments) {
    state.currentAssessment.comments = comments
  },
  SET_PREREQUISITE_ASSESSMENT(state, assessments) {
    state.prerequisiteAssessments = assessments
  },
  PUSH_COMMENT(state, comment) {
    state.currentAssessment.comments = state.currentAssessment.comments.concat([comment])
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  resetSessions({ commit }) {
    commit('RESET_SESSIONS')
  },
  resetCurrentSession({ commit }) {
    commit('RESET_CURRENT_SESSION')
  },
  async fetchAssessmentSessions({ commit }, { assessmentId, per_page, current_page, q }) {
    try {
      const params = q
        ? {
            per_page,
            current_page,
            q,
          }
        : {
            per_page,
            current_page,
          }
      const res = await this.$axios.$get(`/api/v1/dashboard/assessments/${assessmentId}/sessions?include=student`, {
        params,
      })
      commit('SET_SESSIONS', res.data)
      commit('SET_TOTAL_SESSIONS', res.meta.pagination.total)
    } catch (err) {}
  },
  async fetchAssessmentSession({ commit }, { assessmentId, sessionId }) {
    try {
      const res = await this.$axios.$get(`/api/v1/dashboard/assessments/${assessmentId}/sessions/${sessionId}?include=answers.answers,student`)
      commit('SET_CURRENT_SESSION', res.data)
    } catch (err) {}
  },
  async postAnswerGrade({ dispatch }, { assessmentId, sessionId, answerId, grade, feedback }) {
    try {
      await this.$axios.post(`/api/v1/dashboard/assessments/${assessmentId}/sessions/${sessionId}/grade`, {
        answer_id: answerId,
        grade,
        feedback,
      })

      dispatch('fetchAssessmentSession', {
        assessmentId,
        sessionId,
      })
    } catch (err) {}
  },
  async fetchAssessmentComments({ commit, dispatch }, { assessmentId, studentId }) {
    try {
      const res = await this.$axios.$get(`/api/v1/dashboard/assessments/${assessmentId}/students/${studentId}/comments`)
      commit('SET_CURRENT_LESSON_ASSESSMENT_COMMENTS', res.data)
    } catch (error) {}
  },
  async fetchPrerequisiteAssessments({ commit, dispatch }, courseId) {
    try {
      const res = await this.$axios.$get(`/api/v1/dashboard/courses/${courseId}/assessments?type=prerequisite&per_page=-1`)
      commit('SET_PREREQUISITE_ASSESSMENT', res.data)
    } catch (error) {}
  },
  newLiveComment({ commit }, comment) {
    commit('PUSH_COMMENT', comment)
  },
  async postCommentonAssessment({ dispatch }, { assessmentId, studentId, formData }) {
    try {
      const headers = {
        'Content-Type': 'multipart/form-data',
      }
      if (this.$echo.socketId()) {
        headers['X-Socket-ID'] = this.$echo.socketId()
      }
      await this.$axios.$post(`/api/v1/dashboard/assessments/${assessmentId}/students/${studentId}/comments`, formData, {
        headers,
      })
      dispatch('fetchAssessmentComments', {
        assessmentId,
        studentId,
      })
    } catch (error) {}
  },
}
