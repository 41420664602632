export const HOMEWORKS_LOADING = 'HOMEWORKS_LOADING'
export const HOMEWORKS_LOADED = 'HOMEWORKS_LOADED'
export const CURRENT_HOMEWORK_LOADING = 'CURRENT_HOMEWORK_LOADING'
export const CURRENT_HOMEWORK_LOADED = 'CURRENT_HOMEWORK_LOADED'

const defaultState = () => ({
  homeworksStatus: null,
  homeworks: [],

  currentHomework: null,
  currentHomeworkStatus: null,

  page: 1,
  totalPages: 1,
  isLoadingMore: false,
  perPage: null,
  total: null,
})

export const state = () => defaultState()

export const mutations = {
  SET_HOMEWORKS_STATUS(state, status) {
    state.homeworksStatus = status
  },
  SET_CURRENT_HOMEWORK_STATUS(state, status) {
    state.currentHomeworkStatus = status
  },
  SET_HOMEWORKS(state, { homeworks, totalPages, perPage, total }) {
    state.homeworks = homeworks
    state.totalPages = totalPages
    state.perPage = perPage
    state.total = total
  },
  PUSH_HOMEWORKS_PAGE(state, homeworks) {
    state.homeworks = [...state.homeworks, ...homeworks]
    state.page += 1
  },
  SET_CURRENT_HOMEWORK(state, homework) {
    state.currentHomework = homework
    state.currentHomeworkStatus = CURRENT_HOMEWORK_LOADED
  },
  RESET_HOMEWORKS(state) {
    state.homeworksStatus = null
    state.homeworks = []
    state.page = 1
    state.totalPages = 1
    state.isLoadingMore = false
    state.perPage = null
    state.total = null
  },
  RESET_CURRENT_HOMEWORK(state) {
    state.currentHomework = null
    state.currentHomeworkStatus = null
  },
  SET_LOADING_MORE(state, status) {
    state.isLoadingMore = status
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  async fetchLessonHomeworks({ commit }, { lessonId, ...params }) {
    commit('SET_HOMEWORKS_STATUS', HOMEWORKS_LOADING)
    try {
      const {
        data: { data: homeworks, meta },
      } = await this.$axios.get(`/api/v1/lessons/${lessonId}/assessments`, {
        params: {
          type: 'homework',
          ...params,
        },
      })

      commit('SET_HOMEWORKS', {
        homeworks,
        totalPages: meta.pagination.total_pages,
        perPage: meta.pagination.per_page,
        total: meta.pagination.total,
      })
    } catch (error) {
    } finally {
      commit('SET_HOMEWORKS_STATUS', HOMEWORKS_LOADED)
    }
  },

  async fetchCurrentHomework({ commit }, { lessonId, homeworkId }) {
    commit('SET_CURRENT_HOMEWORK_STATUS', CURRENT_HOMEWORK_LOADING)
    try {
      const {
        data: { data: homework },
      } = await this.$axios.get(`/api/v1/lessons/${lessonId}/homeworks/${homeworkId}`)

      commit('SET_CURRENT_HOMEWORK', homework)
    } catch (error) {
    } finally {
      commit('SET_CURRENT_HOMEWORK_STATUS', CURRENT_HOMEWORK_LOADED)
    }
  },
}

export const getters = {
  homeworksLoading: (state) => state.homeworksStatus === HOMEWORKS_LOADING,
  homeworksLoaded: (state) => state.homeworksStatus === HOMEWORKS_LOADED,

  currentHomeworkLoading: (state) => state.currentHomeworkStatus === CURRENT_HOMEWORK_LOADING,
  currentHomeworkLoaded: (state) => state.currentHomeworkStatus === CURRENT_HOMEWORK_LOADED,

  getHomeworks: (state) => state.homeworks.map((homework) => ({ ...homework, authSession: homework.authSession?.data })),

  homeworksLoadingMore: (state) => state.isLoadingMore,
  hasMorePages: (state) => state.page < state.totalPages,
  perPage: (state) => state.perPage,
  total: (state) => state.total,
}
