import { extend } from 'vee-validate'
import { required, length, min, max, regex, oneOf, email, numeric } from 'vee-validate/dist/rules'

export default ({ app: { i18n } }) => {
  extend('required', {
    ...required,
    message: (field) => i18n.t('rules.required', { field }),
  })

  extend('stringLength', {
    ...length,
    message: (field, { length: size }) => i18n.t('rules.size.string', { field, size }),
  })

  extend('stringMin', {
    ...min,
    message: (field, { length: min }) => i18n.t('rules.min.string', { field, min }),
  })

  extend('stringMax', {
    ...max,
    message: (field, { length: max }) => i18n.t('rules.max.string', { field, max }),
  })

  extend('stringBetween', {
    params: ['min', 'max'],
    validate: (value, { min, max }) => value.length >= min && value.length <= max,
    message: (field, { min, max }) => i18n.t('rules.between.string', { field, min, max }),
  })

  extend('digitsBetween', {
    params: ['min', 'max'],
    validate: (value, { min, max }) => value.length >= min && value.length <= max,
    message: (field, { min, max }) => i18n.t('rules.digits_between', { field, min, max }),
  })

  extend('numericLte', {
    params: ['max'],
    ...numeric,
    validate: (value, { max }) => +value <= +max,
    message: (field, { max }) => i18n.t('rules.lte.numeric', { field, value: max }),
  })

  extend('numericGte', {
    params: ['min'],
    ...numeric,
    validate: (value, { min }) => +value >= +min,
    message: (field, { min }) => i18n.t('rules.gte.numeric', { field, value: min }),
  })

  extend('numericGteLength', {
    params: ['min'],
    ...numeric,
    validate: (value, { min }) => value.length >= min,
    message: (field, { min }) => i18n.t('rules.gte.numeric', { field, value: min }),
  })

  extend('numericMin', {
    params: ['min'],
    ...numeric,
    validate: (value, { min }) => +value > +min,
    message: (field, { min }) => i18n.t('rules.gt.numeric', { field, value: min }),
  })

  extend('integer', {
    validate: (value) => !isNaN(value) && !isNaN(parseInt(value)) && parseFloat(value) % 1 === 0,
    message: (field) => i18n.t('rules.integer', { field }),
  })

  extend('regex', {
    ...regex,
    message: (field) => i18n.t('rules.regex', { field }),
  })

  extend('nationalIdFormat', {
    validate: (value) => /^[2-3]\d{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])\d{7}$/.test(value),
    message: (field) => i18n.t('rules.regex', { field }),
  })

  extend('oneOf', {
    ...oneOf,
    message: (field) => i18n.t('rules.in', { field }),
  })
  extend('email', {
    ...email,
    message: (field) => i18n.t('rules.email', { field }),
  })
  extend('numberLength', {
    ...numeric,
    ...length,
    message: (field, { length: digits }) => i18n.t('rules.digits', { field, digits }),
  })
}
