export const LESSONS_LOADING = 'LESSONS_LOADING'
export const LESSONS_LOADED = 'LESSONS_LOADED'
export const CURRENT_LESSON_LOADING = 'CURRENT_LESSON_LOADING'
export const CURRENT_LESSON_LOADED = 'CURRENT_LESSON_LOADED'

const defaultState = () => ({
  lessonsStatus: null,
  currentLessonStatus: null,

  lessons: [],

  currentLesson: null,
})

export const state = () => defaultState()

export const mutations = {
  SET_LESSONS_STATUS(state, status) {
    state.lessonsStatus = status
  },
  SET_CURRENT_LESSON_STATUS(state, status) {
    state.currentLessonStatus = status
    state.lessonsStatus = LESSONS_LOADED
  },
  SET_LESSONS(state, lessons) {
    state.lessons = lessons
  },
  SET_CURRENT_LESSON(state, lesson) {
    state.currentLesson = lesson
    state.currentLessonStatus = CURRENT_LESSON_LOADED
  },
  RESET_CURRENT_LESSON(state) {
    state.currentLesson = null
    state.currentLessonStatus = null
  },
  RESET_LESSONS(state) {
    state.lessons = []
    state.lessonsStatus = null
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  async fetchCourseLessons({ commit }, courseId) {
    commit('SET_LESSONS_STATUS', LESSONS_LOADING)

    try {
      const { data } = await this.$axios.$get(`/api/v1/courses/${courseId}/lessons`, { params: { per_page: -1 } })

      commit('SET_LESSONS', data)
    } catch (error) {
    } finally {
      commit('SET_LESSONS_STATUS', LESSONS_LOADED)
    }
  },
  async fetchCurrentLesson({ commit }, { courseId, lessonId }) {
    commit('SET_CURRENT_LESSON_STATUS', CURRENT_LESSON_LOADING)

    try {
      const { data } = await this.$axios.$get(`/api/v1/courses/${courseId}/lessons/${lessonId}`)

      commit('SET_CURRENT_LESSON', { ...data, resources: data.resources.data })
    } catch (error) {
    } finally {
      commit('SET_CURRENT_LESSON_STATUS', CURRENT_LESSON_LOADED)
    }
  },
}

export const getters = {
  lessonsLoading: (state) => state.lessonsStatus === LESSONS_LOADING,
  lessonsLoaded: (state) => state.lessonsStatus === LESSONS_LOADED,

  currentLessonLoading: (state) => state.currentLessonStatus === CURRENT_LESSON_LOADING,
  currentLessonLoaded: (state) => state.currentLessonStatus === CURRENT_LESSON_LOADED,

  getLessonsByCategory: (state) => (category) => state.lessons.filter((lesson) => lesson.lesson_category_id === category),
  categoryLessonsCount: (state, getters) => (category) => getters.getLessonsByCategory(category).length,

  isCategorized: (state) => state.lessons.some((lesson) => lesson.lesson_category_id !== null),
}
