//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex'
import notificationContainerBase from '~/mixins/notificationContainerBase'

export default {
  mixins: [notificationContainerBase],
  computed: {
    ...mapState({
      unreadCount: (state) => state.notifications.unreadCount,
      unread: (state) => state.notifications.unread,
    }),
    ...mapGetters('notifications', {
      loading: 'unreadLoading',
      loaded: 'unreadLoaded',
    }),
  },
  beforeDestroy() {
    this.$store.commit('notifications/RESET_UNREAD')
    this.$echo.leave(this.channelName)
  },
  mounted() {
    this.$store.dispatch('notifications/fetchUnread')

    this.channelName = `App.User.${this.$auth.user.data.id}`

    this.$echo.private(this.channelName).notification((notification) => {
      this.playAudio()
      this.$store.commit('notifications/NEW_UNREAD_NOTIFICATION', { ...notification, data: notification })
    })
  },
  methods: {
    playAudio() {
      if (!this.audio) {
        this.audio = new Audio(require('~/assets/audio/quite-impressed.ogg'))
      }
      this.audio.play()
    },
  },
}
