// import createLogger from 'vuex/dist/logger'
// export const plugins = [createLogger({})]

export const defaultState = () => ({
  latestTableOptions: {},
})

export const state = () => ({ ...defaultState() })

export const mutations = {
  SET_LATEST_TABLE_OPTIONS(state, { tableId, tableOptions }) {
    state.latestTableOptions[tableId] = JSON.stringify(tableOptions)
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const getters = {
  getUser: (state) => (user) => ({
    ...user.data,
    profile: user.data?.profile?.data,
  }),
  isTableOptionsChanged:
    (state) =>
    ({ tableId, tableOptions }) => {
      return state.latestTableOptions[tableId] !== JSON.stringify(tableOptions)
    },
}

export const actions = {
  RESET_ALL({ commit }) {
    commit('RESET')
    commit('student/courses/RESET')
    commit('student/discussions/RESET')
    commit('student/homeworks/RESET')
    commit('student/lessonCategories/RESET')
    commit('student/lessons/RESET')
    commit('student/questionTemplates/RESET')
    commit('student/quizzes/RESET')
    commit('notifications/RESET')
  },
}
