/* eslint-disable camelcase */
export default {
  methods: {
    async handleNewComment(item) {
      const {
        course_id,
        lesson_id,
        assessment_id,
        // session_id,
      } = item.data

      let path
      if (this.$auth.user.data.type === 'student') {
        path = `/courses/${course_id}/lessons/${lesson_id}/assessments/${assessment_id}`
      } else {
        // path = `/dashboard/courses/${course_id}/lessons/${lesson_id}/assessments/${assessment_id}/followups/allQuestions/${session_id}`
      }

      await this.$router.replace(path)
    },

    async handleAbsentStudentReminder(item) {
      const { course_id, lesson_id } = item.data
      await this.$router.replace(`/courses/${course_id}/lessons/${lesson_id}`)
    },

    async handleVideoPublished(item) {
      const { course_id, lesson_id, video_id } = item.data
      await this.$router.replace(`/courses/${course_id}/lessons/${lesson_id}/videos/${video_id}`)
    },

    async handleNewPost(item) {
      const { course_id, lesson_id } = item.data
      await this.$router.replace(`/dashboard/courses/${course_id}/lessons/${lesson_id}/discussions`)
    },

    async handlePostAccepted(item) {
      // this happens only for student
      const { course_id, lesson_id, post_id } = item.data
      await this.$router.replace(`/courses/${course_id}/lessons/${lesson_id}/discussions/${post_id}`)
    },
    async handleStudentSubscribedToPackage(item) {
      const { course_id } = item.data

      this.$nuxt.$emit('student-subscribed-to-package', course_id)

      await this.$router.push(`/courses/${course_id}/lessons`)
    },

    async markNotificationAsRead(item) {
      if (!item.read_at) {
        try {
          await this.$axios.$put(`/api/v1/notifications/${item.id}/mark-as-read`, {
            params: {
              id: item.id,
            },
          })
          this.$store.commit('notifications/DECREMENT_UNREAD_COUNT')
        } catch (error) {}
      }

      const handlers = {
        'App\\Notifications\\NewComment': this.handleNewComment,
        'comment.new': this.handleNewComment,
        'App\\Notifications\\AbsentStudentReminder': this.handleAbsentStudentReminder,
        'assessment.absent': this.handleAbsentStudentReminder,
        'App\\Notifications\\VideoPublished': this.handleVideoPublished,
        'video.published': this.handleVideoPublished,
        'App\\Notifications\\NewPost': this.handleNewPost,
        'post.new': this.handleNewPost,
        'App\\Notifications\\PostAccepted': this.handlePostAccepted,
        'post.accepted': this.handlePostAccepted,
        'App\\Notifications\\StudentSubscribedToPackage': this.handleStudentSubscribedToPackage,
      }
      const previousName = this.$route.name

      if (item.type in handlers) {
        await handlers[item.type](item)
        if (previousName === this.$route.name) {
          this.$router.go()
        }
      } else {
        // unhandled notification
        await this.$router.replace(`/notifications`)
      }
    },
  },
}
