//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    async doLogout() {
      await this.$store.commit('ui/SET_IS_LOADING', true)
      await this.$auth.logout()
      this.$store.commit('ui/SET_IS_LOADING', false)
      this.$store.dispatch('RESET_ALL')
    },
  },
}
