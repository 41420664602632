const showTermsMobileViewQueryParam = 'showTermsMobileView'

const qParams = new URL(document.location.toString()).searchParams

if (!qParams.has(showTermsMobileViewQueryParam) || qParams.get(showTermsMobileViewQueryParam)?.toLowerCase() !== 'true') {
  /* eslint-disable */
  console.log('setting zoho salesiq mansety', qParams);

  // zoho salesiq brand: mansety Staging
  window.$zoho=window.$zoho || {};$zoho.salesiq = $zoho.salesiq ||
  {widgetcode: "siqf440afee962465d3eac919e54e63d1f9e9c4cb2c26269a8591f41a1cc60e7340", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);

  window.$zoho.salesiq.ready = function () {
    window.$zoho.salesiq.chat.logo("https://i.imgur.com/NfzkpQH.png");
  }
}
