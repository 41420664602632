//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['error'],
  computed: {
    errMessage() {
      try {
        const j = JSON.parse(this.error.message)
        if (j.message.length) {
          return j.message
        }

        switch (this.error.statusCode) {
          case 403:
            return this.$t('errors.unauthorized_action')
          case 404:
            return this.$t('errors.not_found')
          default:
            return this.$t('errors.something_went_wrong_status', { code: this.error.statusCode })
        }
      } catch (_e) {
        return this.error.message
      }
    },
  },
}
