export default ({ $axios, app, redirect, route, error: nuxtError }) => {
  $axios.onError(async (error) => {
    if (error.response?.status === 401) {
      await app.$auth.logout()
    }
    if (error.response?.status === 401 || error.response?.status === 419) {
      if (route.fullPath !== '/login') {
        await app.$store.dispatch('RESET_ALL')
        await redirect('/login')
      }
    } else if (error.response?.status === 403 && app.$auth.user.data.phone_verified) {
      nuxtError({ statusCode: 403, message: app.i18n.t('errors.unauthorized_action') })
    }

    throw error
  })
}
