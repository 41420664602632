import merge from 'lodash.merge'
import ar from './ar'

const mansety = 'Mansety'
const mansetyAr = 'منصتي'

export default merge(ar, {
  site: {
    title: mansety,
    sub_title: mansetyAr,
    description: 'أفضل منصة تعليمية صُممت لخدمة المدرس والطالب\nلتقديم أفضل خدمة واداء تعليمي لجميع مراحل الثانوية العامة\nللحفاظ على وقت الطالب اليومي',
  },
  ui: {
    about_title: mansety,
  },
  nav: {
    explore_courses: 'المواد الدراسية ',
    my_courses: 'موادي',
  },
  footer: {
    description: ` ${mansetyAr}`,
  },
  home: {
    feature: {
      feature1: 'نعمل جاهدين على توفير كافة المواد على المنصة ومن أكثر من مدرس لتتمكنوا من  حضور الدرس مع المدرس المفضل لديكم',
      feature2: 'نعمل على إنتاج محتوى عالي الجودة في استديوهاتنا المتخصصة لأجل ضمان عملية تعليمية مريحة خالية من الشوائب',
      feature3: 'يتم تصميم الأسئلة والواجبات بعناية لضمان المستوى التعليمي لأبنائنا من الطلاب',
      feature4: 'لن تحتاج إلى أي من الكتب الخارجية, لاننا نقوم بتحديث بنك الاسئلة الخاص بنا دوريا بالتعاون مع نخبة من أكبر مدرسين المواد في الجمهورية',
      feature5: 'هدفنا طمانتكم ولذلك يقوم فريق كامل من المساعدين بمتابعة مستوى أبنائنا مع أولياء الأمور ',
      feature6: `ابنائنا الطلبة, يهمنا رايكم ولذلك يمكنكم ارسال ارائكم واستفساراتكم على أي جزء خاص في المنصة سواء كان على الواجبات والامتحانات أو حتى عن المنصة`,
    },
    about: {
      title: '',
      sub_title: ' ',
      text1: `منصتنا هي منصة تعليمية تم تصميمها بمساعدة أكثر مدرسين الثانوية العامة خبرة في جمهورية مصر العربية
      ، وذلك لنساعدك على توفير أكبر وقت ممكن في يومك الدراسي، وكذلك يمكنك حضور الدرس مع مدرسك المفضل في راحة يدك من خلال تطبيق المحمول`,
      text2: '',
    },
  },
  reservation: {
    all_subjects: 'كل المواد',
    apply: 'حجز المادة',
    login_to_register: 'سجل الدخول لحجز المادة',
    confirmation: 'هل أنت متأكد انك تريد حجز المادة {name}؟',
  },
  courses: {
    not_subscribed: 'انت غير مشترك في اي مادة',
    open: 'دخول المادة',
    back: 'رجوع الى المادة',
    cant_load: 'لا يمكن تحميل بيانات المادة الآن، برجاء المحاولة لاحقا',
    content: 'محتوى مادة <em>{course}</em> - <em>{teacher}</em>',
    discover_packages: 'إستكشف كورسات المادة',
    details: 'تفاصيل عن المادة',
    packages: 'الكورسات',
    pick_course: 'اختر مادة',
  },
  packages: {
    details: 'تفاصيل الكورس',
  },
  payments: {
    pay_courseWallet: 'الدفع من خلال محفظة المادة',
    back_to_course: 'العودة الى المادة',
  },
})
