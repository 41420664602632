export const PACKAGES_LOADING = 'PACKAGES_LOADING'
export const PACKAGES_LOADED = 'PACKAGES_LOADED'

const defaultState = () => ({
  packagesStatus: null,
  packages: [],

  page: 1,
  totalPages: 1,
  perPage: null,
  total: null,
})

export const state = () => defaultState()

export const mutations = {
  SET_PACKAGES_STATUS(state, status) {
    state.packagesStatus = status
  },

  SET_PACKAGES(state, { packages, totalPages, perPage, total }) {
    state.packages = packages
    state.totalPages = totalPages
    state.perPage = perPage
    state.total = total
  },

  RESET_PACKAGES(state) {
    state.packagesStatus = null
    state.packages = []
    state.page = 1
    state.totalPages = 1
    state.perPage = null
    state.total = null
  },

  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  async fetchPackages({ commit }, { courseId, ...params }) {
    commit('SET_PACKAGES_STATUS', PACKAGES_LOADING)
    try {
      const {
        data: { data: packages, meta },
      } = await this.$axios.get(`/api/v1/courses/${courseId}/packages`, {
        params: {
          ...params,
        },
      })

      commit('SET_PACKAGES', {
        packages,
        totalPages: meta.pagination.total_pages,
        perPage: meta.pagination.per_page,
        total: meta.pagination.total,
      })
    } catch (error) {
    } finally {
      commit('SET_PACKAGES_STATUS', PACKAGES_LOADED)
    }
  },
}

export const getters = {
  packagesLoading: (state) => state.packagesStatus === PACKAGES_LOADING,
  packagesLoaded: (state) => state.packagesStatus === PACKAGES_LOADED,

  getPackages: (state) =>
    state.packages.map((pack) => ({
      ...pack,
      authSession: pack.authSession?.data,
    })),

  perPage: (state) => state.perPage,
  total: (state) => state.total,
}
