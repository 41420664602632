export const COURSES_LOADING = 'COURSES_LOADING'
export const COURSES_LOADED = 'COURSES_LOADED'
export const CURRENT_COURSE_LOADING = 'CURRENT_COURSE_LOADING'
export const CURRENT_COURSE_LOADED = 'CURRENT_COURSE_LOADED'

const defaultState = () => ({
  courses: [],

  currentCourse: null,

  coursesStatus: null,
  currentCourseStatus: null,
})

export const state = () => defaultState()

export const mutations = {
  SET_COURSES_STATUS(state, status) {
    state.coursesStatus = status
  },
  SET_CURRENT_COURSE_STATUS(state, status) {
    state.currentCourseStatus = status
  },
  SET_COURSES(state, courses) {
    state.courses = courses
  },
  SET_CURRENT_COURSE(state, course) {
    state.currentCourse = course
  },
  RESET_CURRENT_COURSE(state) {
    state.currentCourse = null
    state.currentCourseStatus = null
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  async fetchCourses({ commit }) {
    commit('SET_COURSES_STATUS', COURSES_LOADING)
    try {
      const {
        data: { data: courses },
      } = await this.$axios.get('/api/v1/courses', { params: { include: 'authCourseStudent' } })

      commit('SET_COURSES', courses)
    } catch (error) {
    } finally {
      commit('SET_COURSES_STATUS', COURSES_LOADED)
    }
  },

  async fetchCurrentCourse({ commit }, courseId) {
    commit('SET_CURRENT_COURSE_STATUS', CURRENT_COURSE_LOADING)
    try {
      const {
        data: { data },
      } = await this.$axios.get(`/api/v1/courses/${courseId}`, { params: { include: 'authCourseStudent' } })

      commit('SET_CURRENT_COURSE', data)
    } catch (error) {
    } finally {
      commit('SET_CURRENT_COURSE_STATUS', CURRENT_COURSE_LOADED)
    }
  },
}

export const getters = {
  coursesLoading: (state) => state.coursesStatus === COURSES_LOADING,
  coursesLoaded: (state) => state.coursesStatus === COURSES_LOADED,

  currentCourseLoading: (state) => state.currentCourseStatus === CURRENT_COURSE_LOADING,
  currentCourseLoaded: (state) => state.currentCourseStatus === CURRENT_COURSE_LOADED,

  getAcceptedCourses: (state, _getters, _rootState, rootGetters) => {
    return state.courses
      .map((course) => {
        return {
          ...course,
          authCourseStudent: course.authCourseStudent.data,
          category: course.category?.data,
          teacher: rootGetters.getUser(course.teacher),
        }
      })
      .filter((course) => course.authCourseStudent.status === 'accepted')
  },

  getPendingCourses: (state, _getters, _rootState, rootGetters) => {
    return state.courses
      .map((course) => {
        return {
          ...course,
          authCourseStudent: course.authCourseStudent.data,
          category: course.category?.data,
          teacher: rootGetters.getUser(course.teacher),
        }
      })
      .filter((course) => course.authCourseStudent.status === 'pending')
  },

  getCurrentCourse: (state, _getters, _rootState, rootGetters) => {
    if (!state.currentCourse) {
      return null
    }

    return {
      ...state.currentCourse,
      authCourseStudent: state.currentCourse.authCourseStudent?.data,
      category: state.currentCourse.category?.data,
      teacher: rootGetters.getUser(state.currentCourse.teacher),
    }
  },

  enrolledInCurrentCourse: (state, getters) => {
    return getters.getCurrentCourse?.authCourseStudent?.status === 'accepted'
  },
}
