export default {
  methods: {
    async doLogin(phoneNumber, password) {
      const data = { phone_number: phoneNumber, password }
      await this.$auth.loginWith('laravelSanctum', { data })
      if (this.$auth.user.data.type !== 'student') {
        this.$router.push('/dashboard')
      }
    },
    async doLogout() {
      await this.$store.commit('ui/SET_IS_LOADING', true)
      await this.$auth.logout()
      this.$store.commit('ui/SET_IS_LOADING', false)
      this.$store.dispatch('RESET_ALL')
    },
  },
}
