//
//
//
//
//
//
//
//

const PLATFORMS = {
  facebook: { base: 'https://facebook.com', icon: 'fab fa-facebook-f' },
  twitter: { base: 'https://twitter.com', icon: 'fab fa-twitter' },
  youtube: { base: 'https://youtube.com', icon: 'fab fa-youtube' },
  instagram: { base: 'https://www.instagram.com', icon: 'fab fa-instagram' },
}

export default {
  computed: {
    socials() {
      const links = this.$config.organization.socialLinks
      return Object.keys(links)
        .filter((platform) => Boolean(links[platform]) && Boolean(PLATFORMS[platform]))
        .map((platform) => {
          const { base, icon } = PLATFORMS[platform]
          return { platform, icon, link: `${base}/${links[platform]}` }
        })
    },
  },
}
