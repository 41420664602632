//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      // default: 'ﻗﻢ ﺑﺎﻟﺒﺤﺚ ﻣﺮة اﺧﺮى ﺑﺎﺳﺘﺨﺪام  ﻛﻠﻤﺎت ﺑﺪﻳﻠﺔ',
      default: '',
    },
    image: {
      type: String,
      default: require(`~/assets/images/Empty/search-empty.svg`),
    },
    cardStyle: {
      type: Boolean,
      default: false,
    },
  },
}
