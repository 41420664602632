;(function (i, n, k, r, y, p, t) {
  i[p] = i[p] || {}
  i[p].add =
    i[p].add ||
    function I(j) {
      ;(i[p].a = i[p].a || []).push(j)
    }
  if (!i[p].d) {
    i[p].d = 1 * new Date()
    i[p].t = y
    const a = n.createElement(k)
    const m = n.getElementsByTagName(k)[0]
    a.async = 1
    a.src = r
    i[p].y = t
    m.parentNode.insertBefore(a, m)
  }
})(window, document, 'script', 'https://resource.inkryptvideos.com/v2-a83ns52/ink.js', true, 'inkrypt', '2.x')
