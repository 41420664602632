export const QUIZZES_LOADING = 'QUIZZES_LOADING'
export const QUIZZES_LOADED = 'QUIZZES_LOADED'
export const CURRENT_QUIZ_LOADING = 'CURRENT_QUIZ_LOADING'
export const CURRENT_QUIZ_LOADED = 'CURRENT_QUIZ_LOADED'

const defaultState = () => ({
  quizzesStatus: null,
  quizzes: [],

  currentQuiz: null,
  currentQuizStatus: null,

  page: 1,
  totalPages: 1,
  perPage: null,
  total: null,
  isLoadingMore: false,
})

export const state = () => defaultState()

export const mutations = {
  SET_QUIZZES_STATUS(state, status) {
    state.quizzesStatus = status
  },
  SET_CURRENT_QUIZ_STATUS(state, status) {
    state.currentQuizStatus = status
  },
  SET_QUIZZES(state, { quizzes, totalPages, perPage, total }) {
    state.quizzes = quizzes
    state.totalPages = totalPages
    state.perPage = perPage
    state.total = total
  },
  PUSH_QUIZZES_PAGE(state, quizzes) {
    state.quizzes = [...state.quizzes, ...quizzes]
    state.page += 1
  },
  SET_CURRENT_QUIZ(state, quiz) {
    state.currentQuiz = quiz
    state.currentQuizStatus = CURRENT_QUIZ_LOADED
  },
  RESET_QUIZZES(state) {
    state.quizzesStatus = null
    state.quizzes = []
    state.page = 1
    state.totalPages = 1
    state.perPage = null
    state.total = null
    state.isLoadingMore = false
  },
  RESET_CURRENT_QUIZ(state) {
    state.currentQuiz = null
    state.currentQuizStatus = null
  },
  SET_LOADING_MORE(state, status) {
    state.isLoadingMore = status
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  async fetchLessonQuizzes({ commit }, { lessonId, ...params }) {
    commit('SET_QUIZZES_STATUS', QUIZZES_LOADING)
    try {
      const {
        data: { data: quizzes, meta },
      } = await this.$axios.get(`/api/v1/lessons/${lessonId}/assessments`, {
        params: {
          type: 'quiz',
          ...params,
        },
      })

      commit('SET_QUIZZES', {
        quizzes,
        totalPages: meta.pagination.total_pages,
        perPage: meta.pagination.per_page,
        total: meta.pagination.total,
      })
    } catch (error) {
    } finally {
      commit('SET_QUIZZES_STATUS', QUIZZES_LOADED)
    }
  },

  async fetchCurrentQuiz({ commit }, { lessonId, quizId }) {
    commit('SET_CURRENT_QUIZ_STATUS', CURRENT_QUIZ_LOADING)
    try {
      const {
        data: { data: quiz },
      } = await this.$axios.get(`/api/v1/lessons/${lessonId}/quizzes/${quizId}`)

      commit('SET_CURRENT_QUIZ', quiz)
    } catch (error) {
    } finally {
      commit('SET_CURRENT_QUIZ_STATUS', CURRENT_QUIZ_LOADED)
    }
  },
}

export const getters = {
  quizzesLoading: (state) => state.quizzesStatus === QUIZZES_LOADING,
  quizzesLoaded: (state) => state.quizzesStatus === QUIZZES_LOADED,

  currentQuizLoading: (state) => state.currentQuizStatus === CURRENT_QUIZ_LOADING,
  currentQuizLoaded: (state) => state.currentQuizStatus === CURRENT_QUIZ_LOADED,

  getQuizzes: (state) =>
    state.quizzes.map((quiz) => ({
      ...quiz,
      authSession: quiz.authSession?.data,
    })),

  quizzesLoadingMore: (state) => state.isLoadingMore,
  hasMorePages: (state) => state.page < state.totalPages,
  perPage: (state) => state.perPage,
  total: (state) => state.total,
}
