//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: { Loading },
  computed: {
    ...mapState({
      isLoading: (state) => state.ui.isLoading,
      lightboxImages: (state) => state.ui.lightboxImages,
      showTermsMobileView: (state) => state.ui.showTermsMobileView,
    }),
    ...mapGetters('ui', {
      isLightboxVisible: 'isLightboxVisible',
    }),
  },
  mounted() {
    const { showTermsMobileView } = this.$route.query

    this.$store.commit('ui/SET_SHOW_TERMS_MOBILE_VIEWS', showTermsMobileView?.toLowerCase() === 'true')
  },
  methods: {
    hideLightbox() {
      this.$store.commit('ui/HIDE_LIGHTBOX')
    },
  },
}
