export default async function ({ $auth, redirect, store }) {
  if ($auth.loggedIn && !$auth.user.data.phone_verified) {
    if ($auth.user.data.source === 'signup') {
      return redirect('/verify')
    }

    await $auth.logout()
    store.dispatch('RESET_ALL')
  }
}
