export const QUESTION_TEMPLATES_LOADING = 'QUESTION_TEMPLATES_LOADING'
export const QUESTION_TEMPLATES_LOADED = 'QUESTION_TEMPLATES_LOADED'

const defaultState = () => ({
  questionTemplatesStatus: null,
  questionTemplates: [],
  page: 1,
  totalPages: 1,
  isLoadingMore: false,
})

export const state = () => defaultState()

export const mutations = {
  SET_QUESTION_TEMPLATES_STATUS(state, status) {
    state.questionTemplatesStatus = status
  },
  SET_QUESTION_TEMPLATES(state, { questions, totalPages }) {
    state.questionTemplates = questions
    state.totalPages = totalPages
  },
  PUSH_QUESTION_TEMPLATES_PAGE(state, questions) {
    state.questionTemplates = [...state.questionTemplates, ...questions]
    state.page += 1
  },
  RESET_QUESTION_TEMPLATES(state) {
    state.questionTemplates = []
    state.page = 1
    state.totalPages = 1
  },
  SET_LOADING_MORE(state, status) {
    state.isLoadingMore = status
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  async fetchCourseQuestionTemplates({ commit }, courseId) {
    commit('SET_QUESTION_TEMPLATES_STATUS', QUESTION_TEMPLATES_LOADING)
    try {
      const {
        data: { data: questionTemplates, meta },
      } = await this.$axios.get(`/api/v1/courses/${courseId}/questionTemplates`)
      commit('SET_QUESTION_TEMPLATES', {
        questions: questionTemplates.map((q) => ({ ...q, options: q.options.data })),
        totalPages: meta.pagination.total_pages,
      })
    } catch (error) {
    } finally {
      commit('SET_QUESTION_TEMPLATES_STATUS', QUESTION_TEMPLATES_LOADED)
    }
  },
  async fetchNextPage({ commit, state }, courseId) {
    if (state.page === state.totalPages) {
      return
    }

    commit('SET_LOADING_MORE', true)
    try {
      const {
        data: { data: questionTemplates },
      } = await this.$axios.get(`/api/v1/courses/${courseId}/questionTemplates`, { params: { page: state.page + 1 } })

      commit(
        'PUSH_QUESTION_TEMPLATES_PAGE',
        questionTemplates.map((q) => ({ ...q, options: q.options.data }))
      )
    } catch (error) {
    } finally {
      commit('SET_LOADING_MORE', false)
    }
  },
}

export const getters = {
  questionTemplatesLoading: (state) => state.questionTemplatesStatus === QUESTION_TEMPLATES_LOADING,
  questionTemplatesLoaded: (state) => state.questionTemplatesStatus === QUESTION_TEMPLATES_LOADED,

  questionTemplatesLoadingMore: (state) => state.isLoadingMore,

  hasMorePages: (state) => state.page < state.totalPages,
}
