export default async function ({ $auth, store, redirect, route }) {
  if ($auth.loggedIn && $auth.user.data.type === 'student') {
    await store.commit('ui/SET_IS_LOADING', true)
    store.commit('ui/SET_IS_LOADING', false)
    store.dispatch('RESET_ALL')
    if (route.fullPath.includes('dashboard')) {
      redirect('/courses')
    }
  }
}
