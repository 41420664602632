import Vue from 'vue'
import moment from 'moment'

Vue.filter('str_limit', (value, size = 120, suffix = '...') => {
  if (!value) return ''

  value = value.toString()

  if (value.length <= size) {
    return value
  }

  return value.substr(0, size) + suffix
})

Vue.filter('format_datetime_Day_Month', (value, locale = 'en') => {
  if (!value || !moment(value).isValid()) {
    return value
  }

  return value.locale(locale).format('MMMM Do, H:mm A')
})

Vue.filter('format_datetime', (value, locale = 'en') => {
  if (!value || !moment(value).isValid()) {
    return value
  }

  return value.locale(locale).format('D/M/YYYY, HH:mm A')
})

Vue.filter('format_date', (value, locale = 'en') => {
  if (!value || !moment(value).isValid()) {
    return value
  }

  return value.locale(locale).format('D/M/YYYY')
})

Vue.filter('format_time', (value, locale = 'en') => {
  if (!value || !moment(value).isValid()) {
    return value
  }

  return value.locale(locale).format('HH:mm A')
})
