export default {
  site: {
    title: 'Unipuka',
    sub_title: 'يونيبوكا',
    description: 'ﺗﻌﻠﻢ ﺑﺄﺣﺪث اﻟﻄﺮق ﻣﻦ ﺧﻠﺎل ﻣﻨﺼﺘﻨﺎ,ﻓإﻧﻨﺎ ﻧﻮﻓﺮ ﻟﻚ اﻟﻌﺪﻳﺪ ﻣﻦ اﻟﻜﻮرﺳﺎت\nاﻟﺨﺎﺻﺔ ﺑﺎﻟﻤﺮﺣﻠﺔ اﻟﺜﺎﻧﻮﻳﺔ واﻟﺠﺎﻣﻌﻴﺔ , ﺑﺄﺣﺪث ﻃﺮق اﻟﻤﺘﺎﺑﻌﺔ واﻟﺘﻘﻴﻴﻢ.',
  },
  ui: {
    welcome: 'مرحبا',
    welcome_to_platform: 'مرحبا بك في منصة {platform}',
    select: {
      please_select_options: '-- إختر من الخيارات المتاحة --',
    },
    load_more: 'تحميل المزيد',
    search: 'بحث',
    download: 'تحميل الملف',
    enter_body: 'أضف محتوى',
    confirm: 'تأكيد',
    cancel: 'إلغاء',
    about_title: 'Unipuka.',
    save: 'حفظ',
    reload: 'إعادة التحميل',
    delete: 'مسح',
    settings: 'الاعدادات',
    quill_placeholder: 'أضف محتوى هنا...',
    total: 'الكل',
    perform_search: 'ابحث',
    notes: 'ملاحظات',
    currency: 'الرصيد',
    EGP: 'جنيه',
    export_to_csv: 'تصدير الجدول',
    more_details: 'مزيد من التفاصيل',
    close: 'إغلاق',
    show: 'مشاهدة',
    clear: 'إزالة',
    copy: 'نسخ',
    copied: 'تم نسخ {value} بنجاح',
    back: ' الرجوع إلى {value}',
    duration_minutes: 'المدة بالدقائق',
    type: 'النوع',
    title: 'العنوان',
    view: 'مشاهدة',
    add: 'إضافة',
    answer: 'الاجابة',
    minute: 'دقيقة',
    print: 'طباعة',
    loading: 'جاري التحميل...',
    send: 'إرسال',
    description: 'وصف',
    date: 'التاريخ',
    once: 'مرة',
    times: 'مرات',
    success: 'تم بنجاح',
    update: 'تعديل',
    view_file: 'مشاهدة ملف الإجابة',
    used: 'مستخدم',
    no_used: 'غير مستخدم',
    maintenance_title: 'سنعود قريبا',
    maintenance_summary: 'نأسف على الإزعاج، نحن نجري بعض أعمال الصيانة في الوقت الحالي وسنعود قريبًا',
  },
  nav: {
    contact_us: 'إتصل بنا',
    about_us: 'عن المنصة',
    policy: 'سياسة الإستخدام',
    call_center: 'الدعم: {number}',
    explore_courses: 'إستكشف الكورسات',
    my_profile: 'ملفي الشخصي',
    home: 'الرئيسية',
    my_courses: 'كورساتي',
    continue_to_platform: 'الإستمرار إلى المنصة',
    my_wallet: 'محفظتى',
  },
  footer: {
    description: 'ﻟﻮرﻳﻢ اﺑﺴﻮم ﻫﻮ ﻣﺜﺎل ﺗﻮﺿﻴﺤﻰ ﻟﻄﺮﻳﻘﺔ ﻋﺮض اﻟﻨﺼﻮص ﻓﻰ اﻟﺘﺼﻤﻴﻢ ﻟﻜﻰ ﻳﻘﻮم اﻟﻤﺸﺎﻫﺪ ﺑﺘﺨﻴﻞ ﻃﺮﻳﻘﺔ ﻋﺮض اﻟﻨﺼﻮص',
    channels: 'ﻗﻨﻮات اﻟﺘﻮاﺻﻞ',
    about_us_summary: 'ﻧﺒﺬة ﻋﻦ اﻟﻤﻨﺼﺔ',
    contact_us: 'اﻟﺎﺗﺼﺎل ﺑﻨﺎ',
    privacy_policy: 'ﺳﻴﺎﺳﺔ اﻟﺨﺼﻮﺻﻴﺔ',
    terms_and_conditions: 'اﻟﺸﺮوط واﻟﺎﺣﻜﺎم',
    you_have_agree_terms: 'يجب ان توافق على الشروط والاحكام',
    i_agree: 'اوافق على',
  },
  search: {
    no_results: 'لم ينجح بحثك في إظهار اي نتائج',
  },
  home: {
    explore: {
      title: 'اﺳﺘﻜﺸﻒ ﻣﻮاد اﻟﺼﻔﻮف اﻟﺘﻌﻠﻴﻤﻴﺔ اﻟﻤﻮﺟﻮدة ﺑﺎﻟﻤﻨﺼﺔ',
      sub_title: 'ﺗﻌﻠﻢ ﺑﺄﺣﺪث اﻟﻄﺮق ﻣﻦ ﺧﻠﺎل ﻣﻨﺼﺘﻨﺎ,ﻓﺄﻧﻨﺎ ﻧﻮﻓﺮ ﻟﻚ اﻟﻌﺪﻳﺪ ﻣﻦ اﻟﻜﻮرﺳﺎت اﻟﺨﺎﺻﺔ ﺑﺎﻟﻤﺮﺣﻠﺔ اﻟﺜﺎﻧﻮﻳﺔ واﻟﺠﺎﻣﻌﻴﺔ , ﺑﺄﺣﺪث ﻃﺮق اﻟﻤﺘﺎﺑﻌﺔ واﻟﺘﻘﻴﻴﻢ.',
    },
    feature: {
      title: 'ﺗﻤﺘﻊ ﺑﺄﻓﻀﻞ اﻟﻄﺮق اﻟﺘﻌﻠﻴﻤﻴﺔ ﻣﻦ ﺧﻠﺎل اﻟﻤﻨﺼﺔ',
      sub_title: 'ﺗﻌﻠﻢ ﺑﺄﺣﺪث اﻟﻄﺮق ﻣﻦ ﺧﻠﺎل ﻣﻨﺼﺘﻨﺎ,ﻓﺄﻧﻨﺎ ﻧﻮﻓﺮ ﻟﻚ اﻟﻌﺪﻳﺪ ﻣﻦ اﻟﻜﻮرﺳﺎت اﻟﺨﺎﺻﺔ ﺑﺎﻟﻤﺮﺣﻠﺔ اﻟﺜﺎﻧﻮﻳﺔ واﻟﺠﺎﻣﻌﻴﺔ , ﺑﺄﺣﺪث ﻃﺮق اﻟﻤﺘﺎﺑﻌﺔ واﻟﺘﻘﻴﻴﻢ.',
      feature1_title: 'ﻛﻮرﺳﺎت ﻣﻤﻴﺰة',
      feature1: 'ﻟﻮرﻳﻢ اﻳﺒﺴﻮم ﻫﻮ ﻧﻤﻮذج اﻓﺘﺮاﺿﻲ ﻳﻮﺿﻊ ﻓﻲ اﻟﺘﺼﺎﻣﻴﻢ ﻟﺘﻌﺮض ﻋﻠﻰ اﻟﻌﻤﻴﻞ ﻟﻴﺘﺼﻮر ﻃﺮﻳﻘﻪ',
      feature2_title: 'ﻓﻴﺪﻳﻮﻫﺎت ﻋﺎﻟﻴﺔ اﻟﺠﻮدة',
      feature2: 'ﻟﻮرﻳﻢ اﻳﺒﺴﻮم ﻫﻮ ﻧﻤﻮذج اﻓﺘﺮاﺿﻲ ﻳﻮﺿﻊ ﻓﻲ اﻟﺘﺼﺎﻣﻴﻢ ﻟﺘﻌﺮض ﻋﻠﻰ اﻟﻌﻤﻴﻞ ﻟﻴﺘﺼﻮر ﻃﺮﻳﻘﻪ',
      feature3_title: 'اﺧﺘﺒﺎرات و واﺟﺒﺎت',
      feature3: 'ﻟﻮرﻳﻢ اﻳﺒﺴﻮم ﻫﻮ ﻧﻤﻮذج اﻓﺘﺮاﺿﻲ ﻳﻮﺿﻊ ﻓﻲ اﻟﺘﺼﺎﻣﻴﻢ ﻟﺘﻌﺮض ﻋﻠﻰ اﻟﻌﻤﻴﻞ ﻟﻴﺘﺼﻮر ﻃﺮﻳﻘﻪ',
      feature4_title: 'ﺑﻨﻚ اﺳﺄﻟﺔ',
      feature4: 'ﻟﻮرﻳﻢ اﻳﺒﺴﻮم ﻫﻮ ﻧﻤﻮذج اﻓﺘﺮاﺿﻲ ﻳﻮﺿﻊ ﻓﻲ اﻟﺘﺼﺎﻣﻴﻢ ﻟﺘﻌﺮض ﻋﻠﻰ اﻟﻌﻤﻴﻞ ﻟﻴﺘﺼﻮر ﻃﺮﻳﻘﻪ',
      feature5_title: 'ﺗﻘﻴﻴﻢ ﻣﺴﺘﻤﺮ',
      feature5: 'ﻟﻮرﻳﻢ اﻳﺒﺴﻮم ﻫﻮ ﻧﻤﻮذج اﻓﺘﺮاﺿﻲ ﻳﻮﺿﻊ ﻓﻲ اﻟﺘﺼﺎﻣﻴﻢ ﻟﺘﻌﺮض ﻋﻠﻰ اﻟﻌﻤﻴﻞ ﻟﻴﺘﺼﻮر ﻃﺮﻳﻘﻪ',
      feature6_title: 'تفاعلات',
      feature6: 'ﻟﻮرﻳﻢ اﻳﺒﺴﻮم ﻫﻮ ﻧﻤﻮذج اﻓﺘﺮاﺿﻲ ﻳﻮﺿﻊ ﻓﻲ اﻟﺘﺼﺎﻣﻴﻢ ﻟﺘﻌﺮض ﻋﻠﻰ اﻟﻌﻤﻴﻞ ﻟﻴﺘﺼﻮر ﻃﺮﻳﻘﻪ',
    },
    about: {
      title: 'ﻋﻦ اﻟﻤﻨﺼﺔ',
      sub_title: 'ﺗﻌﻠﻢ ﺑﺄﺣﺪث اﻟﻄﺮق ﻣﻦ ﺧﻠﺎل ﻣﻨﺼﺘﻨﺎ,ﻓﺄﻧﻨﺎ ﻧﻮﻓﺮ ﻟﻚ اﻟﻌﺪﻳﺪ ﻣﻦ اﻟﻜﻮرﺳﺎت اﻟﺨﺎﺻﺔ ﺑﺎﻟﻤﺮﺣﻠﺔ اﻟﺜﺎﻧﻮﻳﺔ واﻟﺠﺎﻣﻌﻴﺔ , ﺑﺄﺣﺪث ﻃﺮق اﻟﻤﺘﺎﺑﻌﺔ واﻟﺘﻘﻴﻴﻢ.',
      text1: `وﺿﻊ اﻟﻨﺼﻮص ﺑﺎﻟﺘﺼﺎﻣﻴﻢ ﺳﻮاء ﻛﺎﻧشﺖ ﺗﺼﺎﻣﻴﻢ ﻣﻄﺒﻮﻋﻪ ... ﺑﺮوﺷﻮر او ﻓﻠﺎﻳﺮ ﻋﻠﻰ ﺳﺒﻴﻞ اﻟﻤﺜﺎل ﻟﻮرﻳﻢ
              اﻳﺒﺴﻮم ﻫﻮ ﻧﻤﻮذج اﻓﺘﺮاﺿﻲ ﻳﻮﺿﻊ ﻓﻲ اﻟﺘﺼﺎﻣﻴﻢ ﻟﺘﻌﺮض ﻋﻠﻰ اﻟﻌﻤﻴﻞ ﻟﻴﺘﺼﻮر ﻃﺮﻳﻘﻪ`,
      text2: `وﺿﻊ اﻟﻨﺼﻮص ﺑﺎﻟﺘﺼﺎﻣﻴﻢ ﺳﻮاء ﻛﺎﻧشﺖ ﺗﺼﺎﻣﻴﻢ ﻣﻄﺒﻮﻋﻪ ... ﺑﺮوﺷﻮر او ﻓﻠﺎﻳﺮ ﻋﻠﻰ ﺳﺒﻴﻞ
              اﻟﻤﺜﺎل ﻟﻮرﻳﻢ اﻳﺒﺴﻮم ﻫﻮ ﻧﻤﻮذج اﻓﺘﺮاﺿﻲ ﻳﻮﺿﻊ ﻓﻲ اﻟﺘﺼﺎﻣﻴﻢ ﻟﺘﻌﺮض ﻋﻠﻰ اﻟﻌﻤﻴﻞ ﻟﻴﺘﺼﻮر ﻃﺮﻳﻘﻪ`,
    },
  },
  dashboard: {
    dashboard: 'لوحة التحكم',
    settings: 'الاعدادات',
    send_messages: 'الرسائل',
    centers: 'المنصات',
    reservations_officer: 'مسئول الحجز',
    search_code: 'البحث عن الاكواد',
  },
  reservation: {
    all: 'الكل',
    all_stages: 'كل المراحل',
    all_years: 'كل السنين',
    all_departments: 'كل الشعب',
    all_subjects: 'كل الكورسات',
    all_teachers: 'كل المدرسين',
    apply: 'حجز الكورس',
    login_to_register: 'سجل الدخول لحجز الكورس',
    pending: 'قيد الإنتظار',
    confirmation: 'هل أنت متأكد انك تريد حجز الكورس {name}؟',
    see_details: 'مشاهدة التفاصيل',
  },
  notifications: {
    notifications: 'الإشعارات',
    see_all: 'مشاهدة الكل',
    no_unread: 'لقد قرأت جميع الاشعارات',
  },
  auth: {
    login: 'تسجيل الدخول',
    register: 'حساب جديد',
    logout: 'ﺗﺴﺠﻴﻞ اﻟﺨﺮوج',
    reset_password: 'تغير كلمة المرور',
    verify: 'تأكيد رقم الموبايل',
    signup: 'انشاء حساب جديد',
    dont_have_account: 'ليس لديك حساب؟',
    have_account: 'لديك حساب قم',
  },
  payments: {
    enter_code: 'أدخل رقم الكارت',
    redeem_code: 'تفعيل الكود',
    my_wallet: 'محفظتى',
    subscribe_via_recharge_card: 'إشحن محفظتك مباشرة عن طريق كارت الشحن',
    subscribe_now: 'اشترك الان',
    charge_now: 'شحن المحفظة',
    back_to_course: 'العودة الى الكورس',
    different_wallet_service: 'طرق شحن خدمة المحفظة المختلفة',
    charge_your_wallet_now: 'قم بشحن محفظتك الان واستمتع بسهولة الاشتراك فى الكورسات',
    current_balance: 'رصيدك الحالى',
    EGP: 'جنيه',
    vodafone_cash: 'فودافون كاش',
    online: 'الدفع اونلاين',
    anotherCard: 'اشحن كارت اخر',
    close: 'إغلاق',
    pay: 'ادفع',
    pay_wallet: 'الدفع من خلال المحفظة',
    pay_platform: 'الدفع من خلال المقر',
    pay_courseWallet: 'الدفع من خلال محفظة الكورس',
    wallet: 'المحفظة',
    fawry: 'الدفع عن طريق فوري',
    fawry_code_message: 'برجاء استخدام هذا الكود {fawryCode} قبل ({expireAt}) عند فوري من خلال منافذ FawryPay او عن طريق ابليكشن myfawry على الموبايل',
    amount: 'المبلغ',
    card_number: 'رقم البطاقة',
    card_exp_month: 'شهر انتهاء الصلاحية',
    card_exp_year: 'عام انتهاء الصلاحية',
    cvv: 'كود CVV',
    payment_success: 'تمت عملية الدفع بنجاح',
    payment_fail: 'فشلت عملية الدفع',
    payment_checking: 'يتم التحقق من عملية الدفع',
  },
  users: {
    users: 'المستخدمون',
    type: {
      type: 'الوظيفة',
      admin: 'أدمن',
      teacher: 'مدرس',
      assistant: 'مساعد',
      supervisor: 'مشرف',
      accountant: 'محاسب',
      student: 'طالب',
    },
    name: 'الاسم',
    first_name: 'الاسم الأول',
    second_name: 'الاسم الثاني',
    third_name: 'الاسم الثالث',
    last_name: 'الاسم الرابع',
    phone_number: 'رقم التليفون',
    national_id: 'الرقم القومى',
    email: 'البريد الالكترونى',
    create: 'إضافة مستخدم',
    job: 'الوظيفة',
    search: 'ابحث بالاسم أو الرقم القومي أو الموبايل',
    details: 'تفاصيل اخرى',
    phone_verified: 'التحقق من الهاتف',
    settings: 'الاعدادات',
    loading: 'جارى تحميل المستخدمين',
    new_title: 'إضافة مستخدم جديد',
    unverify: 'الغاء التفعيل',
    update: 'تعديل مستخدم',
    edit_title: 'تعديل المستخدم',
    cant_add_users: 'غير مصرح لك بإضافة مستخدمين',
    unverify_confirmation: 'هل انت متأكد انك تريد الغاء تفعيل المستخدم؟',
    verify: 'تفعيل',
    verify_confirmation: 'هل انت متأكد انك تريد تفعيل المستخدم؟',
    cant_verify_users: 'غير مصرح لك بتفعيل المستخدمين',
    cant_unverify_users: 'غير مصرح لك بإلغاء تفعيل المستخدمين',
    user_verified: 'تم تفعيل المستخدم',
    user_unverified: 'تم الغاء تفعيل المستخدم',
    user_added: 'تم اضافة المستخدم بنجاح',
    user_updated: 'تم تعديل المستخدم بنجاح',
  },
  login: {
    enter_phone_number: 'ادخل رقم الموبايل المسجل',
    check_phone_number: 'تحقق من رقم الموبايل',
    failed: 'بيانات الاعتماد هذه غير متطابقة مع البيانات المسجلة لدينا.',
    not_found: 'رقم الموبايل غير مسجل',
    send_otp: 'إرسال رمز التفعيل',
    verify: 'تفعيل رقم الموبايل',
    forgot_password: 'نسيت كلمة المرور ؟',
    registered_successfully: 'تم التسجيل بنجاح، برجاء تسجيل الدخول',
  },
  signup: {
    step_1: 'الخطوة الأولى',
    step_2: 'الخطوة الثانية',
    step_3: 'الخطوة الثالثة',
    step_4: 'الخطوة الرابعة',
    next: 'التالي',
    back: 'رجوع',
    student_info: 'ﺑﻴﺎﻧﺎت اﻟﻄﺎﻟﺐ',
    education_level: 'المرحلة الدراسية',
    parent_info: 'بيانات ولى الامر',
    password: 'انشاء كلمة المرور',
    category: {
      root: 'نوع التسجيل',
      year: 'السنة',
      section: 'الشعبة',
    },
    invalid_info: 'بعض المعلومات غير صحيحة، برجاء تصحيحها قبل التسجيل',
  },
  reset_password: {
    send_otp: 'إرسال رمز التحقق',
    send_otp_to: 'سنرسل رمز التحقق إلى',
    resend_otp: 'إعادة إرسال رمز التحقق',
    password_reset_successfully: 'تم تغير كلمة المرور بنجاح!',
  },
  verify: {
    send_otp: 'إرسال رمز التفعيل',
    send_otp_to: 'إرسال رمز التفعيل إلى',
    resend_otp: 'إعادة إرسال رمز التفعيل',
  },
  courses: {
    code_batches: 'دفعات الاكواد',
    not_subscribed: 'انت غير مشترك في اي كورس',
    open: 'دخول الكورس',
    pending_courses: 'قيد الإنتظار',
    confirm_subscription: 'برجاء الإتصال ب <b>{number}</b> لتأكيد الحجز',
    lessons_count: 'عدد الدروس',
    info: 'معلومات',
    lessons: 'الدروس',
    question_templates: 'بنك الأسئلة',
    back: 'رجوع الى الكورس',
    cant_load: 'لا يمكن تحميل بيانات الكورس الآن، برجاء المحاولة لاحقا',
    content: 'محتوى كورس <em>{course}</em> - <em>{teacher}</em>',
    discover_packages: 'إستكشف باقات الكورس',
    details: 'تفاصيل عن الكورس',
    packages: 'الباقات',
    courses: 'الكورسات',
    teacher: 'المدرس',
    accountants: 'المحاسبين',
    accountant: 'محاسب',
    assistants: 'المساعدين',
    assistant: 'مساعد',
    loading: 'جارى تحميل الكورسات ',
    search: 'ابحث باسم الكورس أو المدرس',
    resync: 'اعادة توزيع الطلبة',
    title: 'عنوان الكورس',
    update: 'تعديل مادة',
    students: 'الطلاب المسجلين',
    student: 'طالب',
    accepted_students: 'الطلبة المقبولة',
    pending_students: 'الطلبة قيد الإنتظار',
    archived_students: 'الطلبة المأرشفة',
    create: 'إضافة كورس',
    supervisors: 'المشرفين',
    supervisor: 'مشرف',
    image: 'صورة الكورس',
    type: 'نوع الكورس',
    summary: 'وصف مختصر',
    reservation_info: 'معلومات حجز الكورس',
    rank: 'الترتيب',
    delete_confirmation: 'هل انت متأكد انك تريد مسح الكورس؟',
    course_deleted: 'تم حذف الكورس بنجاح',
    course_created: 'تم انشاء كورس جديد',
    course_updated: 'تم تعديل الكورس بنجاح',
    manage_students: 'إدارة الطلبة',
    manage_code_batches: 'إدارة الاكواد',
    manage_lessons: 'إدارة الدروس',
    manage_packages: 'إدارة الباقات',
    manage_transactions: 'إدارة الحسابات',
    manage_messages: 'الرسائل',
    manage_reporting: 'التقارير',
    manage_lesson_categories: 'إدارة فئات الدروس',
    manage_question_template: 'إدارة بنك الأسئلة',
    export: 'تصدير',
    pick_course: 'اختر كورس',
  },
  transactions: {
    amount: 'المبلغ',
    balance: 'الرصيد',
    currency: 'جنيه',
    add_transaction: 'اضف معاملة',
    added_successfully: 'تم اضافة المعاملة بنجاح',
  },
  course: {
    course_details: 'تفاصيل الكورس',
    subject: 'الكورس',
    teacher: 'المدرس',
    description: 'وصف الكورس',
    supervisors: 'المشرفين',
    supervisor: 'مشرف',
    accountants: 'المحاسبين',
    accountant: 'محاسب',
    assistants: 'المساعدين',
    assistant: 'مساعد',
    students_loading: 'جارى تحميل الطلبة',
    course_content: 'محتوى الكورس',
    students: 'الطلاب',
  },
  students: {
    students: 'الطلبة',
    search: 'ابحث باسم الطالب',
    new_title: 'مستخدم/طالب جديد',
    edit_title: 'تعديل المستخدم',
    subscription_status: 'حالة الإشتراك',
    subscribed_until: 'مشترك حتى',
    delete_confirmation: 'هل انت متأكد انك تريد الغاء اشتراك الطالب؟',
    subscribe_confirmatio: 'هل انت متأكد انك تريد اضافة اشتراك للطلبة؟',
    choose_feedback: 'اختر طالب',
    activate_code: 'تفعيل الكود',
    activated: 'مفعل',
    not_activated: 'غير مفعل',
    status: 'الحالة',
    pending: 'قيد الانتظار',
    accepted: 'مقبول',
    archived: 'مؤرشف',
    all: 'الكل',
    accept: 'قبول الطالب',
    archive: 'ارشفة الطالب',
    status_changed: 'تم تغيير الحالة بنجاح',
    search_with_phone: 'بحث برقم التليفون',
    not_found: 'الطالب غير موجود',
    number_of_enrolled_courses: 'عدد المواد المشترك بها',
    balance: 'الرصيد',
    wallet_transactions: 'المعاملات',
    reporting: 'التقارير',
    national_id: 'الرقم القومى',
    phone_number: 'رقم التليفون',
    name: 'الاسم',
    code: 'الكود',
    assigned_assistant: 'المساعد المسؤول',
    first_parent_number: 'رقم ولي الأمر الأول',
    second_parent_number: 'رقم ولي الأمر الثاني',
    add_students: 'إضافة طلاب',
    totalStudents: 'عدد الطلاب',
    students_added: 'تم اضافة الطلاب بنجاح',
    add_student: 'إضافة مستخدم و طالب',
    email: 'البريد الالكترونى',
    home_number: 'رقم المنزل',
    address: 'العنوان',
    student_added: 'تم اضافة الطالب بنجاح',
    phone_verified: 'رقم التليفون مفعل',
    unverified_phone_number: 'رقم التليفون غير مفعل.',
    student_details: 'معلومات عن الطالب',
    student_updated: 'تم تحديث الطالب بنجاح',
    student_deleted: 'تم حذف الطالب بنجاح',
  },
  wallet_transactions: {
    title: 'المعاملة',
    student: 'الطالب',
    course: 'الكورس',
    amount: 'المبلغ',
    final_balance: 'الرصيد',
    payable: 'المعاملة',
    created_at: 'تاريخ المعاملة',
    loading: 'جارى تحميل المعاملات',
  },
  packages: {
    loading: 'جارى تحميل الباقات',
    subscribe_now: 'إشترك الآن',
    details: 'تفاصيل الباقة',
    lessons_count: 'عدد الدروس',
    subscribed_successfully: 'تم الإشتراك بنجاح',
    cost: 'تكلفة الاشتراك',
    lesson: 'درس',
    EGP: 'جنيه',
    Choose_subscription_way: 'اختر طريقة الاشتراك الانسب إليك',
    subscribe_again: 'اشترك مرة اخرى',
    codes: 'الاكواد',
    add_codes: 'اضافة اكواد',
    packages: 'الباقات',
    search: 'ابحث باسم الباقة',
    search_2: 'ابحث باسم المشترك',
    search_student: 'ابحث باسم الطالب او رقم الهاتف او الرقم القومي',
    add_subscribers: 'اضافة مشتركين جدد',
    add_all_students: 'اضف جميع الطلاب',
    add_all_students_confirmation: 'متأكد انك تريد اضافة جميع الطلاب في هذه الباقة؟',
    new_title: 'باقة جديد',
    edit_title: 'تعديل الباقة',
    create: 'إضافة باقة',
    title: 'إسم الباقة',
    update: 'تعديل الباقة',
    lessons: 'الدروس',
    type: 'نوع الباقة',
    price: 'سعر الباقة',
    private: 'خاصة',
    public: 'عامة',
    subscriptions: 'الإشتراكات',
    absent: 'الغياب',
    no_subscriptions: 'لا يوجد إشتراكات',
    seller: 'البائع',
    refunder: 'المسترجع',
    selling_method: 'طريقة البيع',
    refunding_method: 'طريقة الإسترداد',
    students_number: 'عدد الطلبة',
    purchased: 'عدد مرات الشراء',
    refund: 'إسترداد الإشتراك',
    non_refundable: 'هذا الإشتراك مسترد بالفعل',
    date_of_purchase: 'تاريخ الشراء',
    views_count: 'عدد المشاهدات بالإشتراك',
    refund_confirmation: 'برجاء إدخال قيمة إسترجاع الإشتراك',
    refund_amount: 'قيمة إسترجاع الإشتراك',
    refund_percentage: 'سيتم استرداد {percentage}٪ من قيمة الباقة {price} جنيه.',
    delete_title: 'تحذير',
    delete_confirmation: 'هل انت متأكد انك تريد حذف الباقة ؟',
    delete: 'حذف الباقة',
    sold_subscriptions_number: 'الاشتراكات المباعة',
    refunded_subscriptions_number: 'الاشتراكات المستردة',
    subscribed_students: 'الطلاب المشتركين بالفعل ',
    remove_subscribed_students: 'ازالة',
    subscribed_students_notes: 'هؤلاء الطلاب سوف يتم اشتراكهم بالباقة مرة اخرى',
    package_added: 'تم اضافة الباقة بنجاح',
    package_updated: 'تم تعديل الباقة بنجاح',
    package_deleted: 'تم حذف الباقة بنجاح',
    students_added: 'تم اضافة الطلاب بنجاح',
    refund_deleted: 'تم مسح الإشتراك بنجاح',
    this_user_has_been_add: 'هذا المستخدم مضاف',
  },

  codes: {
    search: 'بحث برقم التسلسل او كود الكارت',
    details: 'تفاصيل الكود',
    value: 'قيمة الكارت',
    search_submit: 'بحث',
    serial_number: 'رقم التسلسل',
    pin_code: 'كود الكارت',
    printed_at: 'مطبوع',
    used_at: 'مستخدم',
    used_by: 'مستخدم بواسطة',
    created_at: 'تاريخ انشاء الكود',
    archive: 'ارشفة',
    count: 'عدد الاكواد',
    batch_name: 'اسم الدفعة',
    course_name: 'اسم الكورس',
    confirm_title: 'برجاء تأكيد',
    confirm_message: 'هل انت متأكد انك تريد ارشفة هذا الكود ؟',
    export: 'تصدير',
    export_all: 'تصدير الكل',
  },

  lesson_categories: {
    without_category: 'بدون فئة',
    loading: 'جارى تحميل فئات الدروس',
    name: 'إسم الفئة',
    search: 'إبحث في فئات الدروس',
    delete_confirmation: 'هل انت متأكد انك تريد مسح الفئة؟',
    create: 'إضافة فئة',
    update: 'تعديل الفئة',
    new_title: 'فئة جديدة',
    edit_title: 'تعديل فئة',
    no_results: 'لا يوجد فئات',
    updated: 'تم تعديل الفئة بنجاح',
    added: 'تم اضافة الفئة بنجاح',
    deleted: 'تم حذف الفئة بنجاح',
  },
  lessons: {
    info: 'معلومات',
    details: 'تفاصيل الدرس',
    videos_count: 'عدد الفيديوهات',
    homeworks_count: 'عدد الواجبات',
    quizzes_count: 'عدد الامتحانات',
    videos: 'الفيديوهات',
    video: 'فيديو',
    homeworks: 'الواجبات',
    quizzes: 'الإمتحانات',
    back: 'رجوع الى الدرس',
    discussions: 'المناقشات',
    attachments: 'المرفقات',
    cant_load: 'لا يمكن تحميل بيانات الدرس الآن، برجاء المحاولة لاحقا',
    no_attachments: 'لا يوجد مرفقات',
    lessons: 'الدروس',
    new_title: 'درس جديد',
    edit_title: 'تعديل الدرس',
    search: 'ابحث باسم الدرس',
    show: 'مشاهدة الدرس',
    create: 'إضافة درس',
    update: 'تعديل الدرس',
    title: 'عنوان الدرس',
    description: 'وصف الدرس',
    delete_confirmation: 'هل انت متأكد انك تريد مسح الدرس؟',
    manage_videos: 'إدارة الفيديوهات',
    assessments: 'الإختبارات',
    manage_assessments: 'إدارة الإختبارات',
    no_videos: 'لا يوجد فيديوهات',
    students_views: '(عدد مشاهدات الطلاب)',
    lessons_feedback: 'اختر درس',
    lesson_category: 'فئة الدرس',
    download: 'تحميل',
    loading: 'جارى تحميل الدروس',
    status: 'الحالة',
    discussion: 'مناقشة الدرس',
    lesson_added: 'تم اضافة الدرس بنجاح',
    back_to_lessons: 'الرجوع إالى قائمة الدروس',
    lesson_updated: 'تم تعديل الدرس بنجاح',
    lesson_loading: 'جارى تحميل تفاصيل الدرس',
  },
  videos: {
    no_results: 'لا يوجد فيديوهات',
    views: 'المشاهدات',
    remaining_views: 'لديك {remaining_views} مشاهدات من مجموع {total_views} مشاهدات',
    cant_watch: 'عفوا! ، لقد استهلكت جميع مشاهدات هذا الفيديو',
    not_found: 'هذا الفيديو غير موجود، برجاء التواصل مع الدعم الفني.',
    no_more_remaining_view: 'لا يوجد مشاهدات متبقية',
    show: 'مشاهدة الفيديو',
    cant_load: 'لا يمكن تحميل بيانات الفيديو الآن، برجاء المحاولة لاحقا',
    search: 'ابحث باسم الفيديو',
    video: 'فيديو',
    videos: 'الفيديوهات',
    create: 'اضافة فيديو',
    update: 'تعديل الفيديو',
    view: 'مشاهدة',
    title: 'عنوان الفيديو',
    description: 'وصف الفيديو',
    url: 'رابط الفيديو',
    delete_confirmation: 'هل انت متأكد انك تريد مسح الفيديو؟',
    new_title: 'فيديو جديد',
    edit_title: 'تعديل الفيديو',
    loading: 'جارى تحميل الفيديوهات',
    video_added: 'تم اضافة الفيديو بنجاح',
    video_updated: 'تم تعديل الفيديو بنجاح',
  },

  video_views: {
    info: 'معلومات المشاهدات',
    created_at: 'تاريخ مشاهدة الفيديو',
    subscription_views: 'مشاهدات الإشتراك',
    extra_views: 'مشاهدات إضافية',
    add_extra_view: 'إضافة مشاهدة',
    remove_extra_view: 'إلغاء مشاهدة',
  },
  questions: {
    essay: 'مقالي',
    file: 'ملف',
    single_answer: 'إختر الإجابة الصحيحة',
    multiple_answer: 'إختر الإجابات الصحيحة',
    question: 'السؤال',
    search: 'ابحث باسم السؤال',
    answers: 'الإجابات',
    answer: 'الاجابة',
    write_answer: 'اكتب الاجابة...',
    essay_answers: 'الإجابات المقالية',
    options: 'الخيارات',
    add_option: 'اضافة خيار',
    remove_option: 'حذف الخيار',
    option: 'الاختيار',
    body: 'المحتوى',
    title: 'عنوان السؤال',
    type: 'نوع السؤال',
    grade: 'الدرجة',
    choices: 'الإختيارات',
    choice: 'الإختيار',
    is_correct: 'الإختيار الصحيح',
    no_questions: 'لا يوجد اسئلة',
    create: 'إضافة سؤال',
    edit: 'تعديل السؤال',
    delete: 'مسح السؤال',
    repeat: 'تكرار السؤال',
    image: 'صورة السؤال',
    choice_image: 'صورة الإختيار',
    delete_confirmation: 'هل انت متاكد من مسح السؤال؟',
    save: 'حفظ السؤال',
    answered: 'تم حفظ الاجابة',
    write_body: 'اكتب محتوى السؤال ...',
  },
  assessments: {
    notify_absent: 'ارسال اشعارات للطلبة المتغيبة',
    notify_absent_confirmation: 'متأكد انك تريد ارسال اشعارات للطلبة المتغيبة؟',
    assessment_deleted: 'تم حذف اختبار/امتحان بنجاح',
    submit_at: 'معاد التسليم',
    didnt_submit_yet: 'لم يتم التسليم بعد',
    student_grade: 'درجة الطالب',
    student_grade_feedback: 'تعليق علي الدرجة',
    no_questions: 'لا يوجد اسئلة',
    a_question: 'سؤال',
    delete_session: 'هل انت متأكد أنك تريد مسح إجابة الطالب؟',
    questions_count: 'عدد الاسئلة',
    questions_types: 'انواع الاسئلة',
    auto_add_questions: 'اضف اوتوماتيكيا',
    pick_questions: 'اختر بنفسك',
    randomize_questions: 'ترتيب الاسئلة عشوائياً',
    randomize_options: 'ترتيب الاختيارات عشوائياً',
    add_from_question_bank: 'اضف من بنك الاسئلة',
    add_from_assessment: 'اضف من امتحان اخر',
    assessment_added: 'تم اضافة اختبار/امتحان بنجاح',
    assessment_updated: 'تم تعديل اختبار/امتحان بنجاح',
    new_title: 'اختبار/واجب جديد',
    edit_title: 'تعديل الاختبار/الواجب',
    now: 'الآن',
    loading: 'جارى تحميل الامتحانات والوجبات',
    open_at: 'معاد البدأ',
    autograded: 'تصحيح اوتوماتيكي',
    absence: 'الغياب',
    add: 'إضافة',
    loading_absence: 'جارى تحميل الغياب',
    homeworks: 'الواجبات',
    quizzes: 'الإمتحانات',
    search: 'ابحث باسم الاختبار او الواجب',
    followup: 'متابعة',
    followups: 'المتابعات',
    loading_followups: 'جارى تحميل المتاعبين',
    prerequisite_dialog: {
      desc: 'يتوجب عليك حل الاختبار اولا',
      yes: 'ابدأ الاختبار',
      no: 'لا',
    },
    close_at: 'معاد التسليم',
    duration_minutes: 'المدة بالدقائق',
    no_close_at: 'مفتوح',
    grade: 'الدرجة',
    your_grade: 'درجتك',
    total_grade: 'الدرجة الكلية',
    number_of_questions: 'عدد الأسئلة',
    details: 'تفاصيل اكتر',
    solve: 'ابدأ الحل',
    submitted_at: 'تم التسليم',
    from_start: 'من البدأ',
    submit: 'تسليم الإجابات',
    answered_questions: 'عدد الاسألة المجاب عنها: {answered} من {total}',
    correct_answers: 'الإجابات الصحيحة',
    incorrect_answers: 'الإجابات الخاطئة',
    not_graded_answers: 'اسئلة لم يتم تصحيحها بعد',
    answer: 'الإجابة',
    save_answer: 'حفظ الإجابة',
    cant_answer: 'غير مصرح لك بالإجابة',
    saving_answer: 'جاري الحفظ',
    save_success: 'تم حفظ الإجابة بنجاح',
    save_error: 'لم يتم حفظ الإجابة!',
    enter_your_answer: 'إكتب إجابتك',
    attach_file: 'إرفاق ملف',
    download_answer: 'تحميل ملف الإجابة',
    no_answer: 'لم يتم الإجابة على السؤال',
    waiting_for_grade: 'فى انتظار تصحيح الاختبار',
    cant_comment: 'لا يمكنك التعليق حاليا ، برجاء المحاولة لاحقا',
    submission_confirmation: 'هل انت متأكد انك تريد تسليم الإجابات؟',
    submission_confirmation_description: 'لن يمكنك تعديل إجاباتك بعد التسليم',
    assessment_some_questions_without_answers: 'تحذير: يوجد بعض الاسئلة بدون اجابة',
    feedback: 'تعليق المصحح',
    homework: {
      homework: 'واجب',
      no_results: 'لا يوجد واجبات',
      open: 'متابعة الواجب',
      cant_open: 'غير مصرح لك بفتح الواجب',
      cant_submit: 'لا يمكن تسليم الواجب الآن، برجاء المحاولة لاحقا',
      confirmation: 'هل تريد بالفعل بدء الواجب؟',
      confirmation_description: 'سوف يتم احتساب الوقت بمجرد الموافقة',
      update: 'تعديل الواجب',
      delete_confirmation: 'هل انت متأكد انك تريد مسح الواجب؟',
    },
    quiz: {
      delete_confirmation: 'هل انت متأكد انك تريد مسح الإختبار؟',
      quiz: 'إمتحان',
      no_results: 'لا يوجد إمتحانات',
      open: 'متابعة الإمتحان',
      cant_open: 'غير مصرح لك بفتح الإمتحان',
      cant_submit: 'لا يمكن تسليم الإمتحان الآن، برجاء المحاولة لاحقا',
      confirmation: 'هل تريد بالفعل بدء الإمتحان',
      confirmation_description: 'سوف يتم احتساب الوقت بمجرد الموافقة',
      update: 'تعديل الامتحان',
    },
    comments: {
      comment: 'تعليق',
      comments: 'التعليقات',
      no_comments: 'لا يوجد تعليقات ، أضف تعليق الآن',
      new_comment: 'كتابة تعليق',
      write_comment: 'قم بكتابة تعليقك',
      save_comment: 'اضافة التعليق',
      attach_image: 'ارفق صورة',
      attach_file: 'ارفق ملف',
      saving_comment: 'جاري إضافة التعليق',
      only_one_minute: 'يمكنك التسجيل بحد أقصى دقيقة واحدة',
      add_comment: 'إضافة تعليق',
      view_file: 'مشاهدة الملف',
      view_image: 'مشاهدة الصورة',
      play_recording: 'سماع التسجيل',
      add_image: 'إضافة صورة',
      add_pdf: 'إضافة ملف',
      recording: 'يتم التسجيل...',
    },
    prerequisite: 'اختبار مسبق',
    finish_prerequisite: 'يتوجب عليك حل الإختبار الاجبارى أولا قبل مشاهدة الفيديو',
    start_exam: 'بدء الإختبار',
    exam_grade: 'درجة الإختبار',
    view_exam_result: 'رؤية نتيجة الإختبار',
  },
  answers: {
    your_grade: 'درجتك',
    student_grade: 'درجة الطالب',
    not_graded: 'لم يتم التصحيح بعد',
    grader: 'المصحح',
    no_answer: 'لم يتم الإجابة على السؤال',
    view_file: 'مشاهدة ملف الإجابة',
    answer: 'الإجابة',
    submit_grade: 'حفظ الدرجة',
    update_grade: 'تعديل الدرجة',
    correct_answer: 'الإجابة الصحيحة',
    correct_answers: 'الإجابات الصحيحة',
    image: 'صورة الاجابة',
    file: 'ملف الاجابة',
  },
  discussions: {
    loading: 'جارى تحميل المناقشات',
    discussion: 'المناقشة',
    details: 'تفاصيل',
    see_more: 'مشاهدة المناقشة',
    no_posts: 'لا يوجد مناقشات',
    cant_load: 'لا يمكن تحميل بيانات المناقشة الآن، برجاء المحاولة لاحقا',
    reply: 'الرد',
    body: 'المحتوى',
    start_discussion: 'بدأ مناقشة',
    send: 'إرسال',
    attach_image: 'إرفاق صورة',
    attach_file: 'إرفاق ملف',
    enter_your_question: 'إكتب سؤالك...',
    added_successfully: 'تم إستلام طلبك بنجاح',
    remove: 'تراجع',
    wait_question_accepted: 'انتظر حتى يتم قبول سؤالك',
    pending_discussions: 'المناقشات قيد الانتظار',
    student_question: 'سؤال الطالب',
    reply_body: 'نص الرد',
    search: 'ابحث',
    reset_search: 'إلغاء البحث',
    no_results: 'لا يوجد نتائج',
    post_image: 'صورة المنشور',
    post_file: 'ملف المنشور',
    reply_image: 'صورة الرد',
    reply_file: 'ملف الرد',
    reply_recording: 'التسجيل الصوتي للرد',
    student: 'الطالب',
    status: 'الحالة',
    reject: 'رفض',
    archive: 'أرشفة',
    accept: 'الموافقة على النشر',
    restore: 'اعادة النشر',
    add: 'إضافة منشور',
    rejection_reason: 'سبب الرفض',
    use_originals: 'اختر ',
    use_original_file: 'استخدم الملف الاصلي',
    use_original_image: 'استخدم الصورة الاصلية',
    use_original_recording: 'استخدم التسجيل الاصلي',
    save_reply: 'حفظ الرد',
    post_submitted: 'تم ارسال المنشور',
    post_archived: 'تم ارشفة المنشور',
    post_restored: 'تم استعادة المنشور',
    post_rejected: 'تم رفض المنشور',
    reply_submitted: 'تم ارسال ال الرد',
    pending: 'انتظار',
    archived: 'مؤرشف',
    accepted: 'مقبول',
    rejected: 'مرفوض',
    post_unauthorized: 'انت غير مصرح لك مشاهدة هذا المنشور',
    created_at: 'تاريخ المناقشة',
    scopes: {
      all: 'جميع المناقشات',
      mine: 'مناقشاتي فقط',
    },
  },
  question_templates: {
    no_results: 'لا يوجد اسئلة',
    level: 'مستوى السؤال',
    easy: 'سهل',
    medium: 'متوسط',
    hard: 'صعب',
    show_answer: 'رؤية الإجابة',
    answer: 'الإجابة',
    download: 'تحميل الملف',
    questions: 'الاسئلة',
    search: 'ابحث باسم السؤال',
    new_title: 'سؤال جديد',
    edit_title: 'تعديل السؤال',
    update: 'تعديل السؤال',
    create: 'إضافة سؤال',
    title: 'عنوان السؤال',
    body: 'وصف السؤال',
    visibility: 'عرض واخفاء السؤال للطلبة',
    public: 'سؤال مرئي للطلبة',
    private: 'سؤال غير مرئي للطلبة',
    answer_file: 'رفع ملف الاجابه',
    associated_lessons: 'الدروس الخاصه بالسؤال',
    question_tags: 'وسوم السؤال',
    add_tag_hint: 'اكتب الوسم و اضغط Enter',
    enter_to_add: 'اضفط Enter لإضافة {tag}',
    original_file: 'الملف الأصلي',
    question_template_added: 'تم اضافة سؤال بنجاح',
    question_template_updated: 'تم تعديل سؤال بنجاح',
    question_template_deleted: 'تم حذف سؤال بنجاح',
  },
  messages: {
    notify_all_students: 'إرسال لكل طلبة الكورس',
    title: 'عنوان الرسالة',
    body: 'محتوى الرسالة',
    send: 'إرسال',
    sent_successfully: 'تم إرسال الرسالة بنجاح',
  },
  fields: {
    user: {
      first_name: 'الاسم الأول',
      second_name: 'الاسم الثاني',
      third_name: 'الاسم الثالث',
      last_name: 'الاسم الرابع',
      phone_number: 'رقم الموبايل',
      password: 'كلمة المرور',
      new_password: 'كلمة المرور',
      otp_code: 'رمز التفعيل',
      name: 'الإسم',
      national_id: 'الرقم القومي',
      address: 'العنوان',
      first_parent_number: 'رقم ولي الأمر الأول',
      second_parent_number: 'رقم ولي الأمر الثاني',
      home_number: 'رقم المنزل',
      password_confirmation: 'تأكيد كلمة المرور',
      email: 'البريد الالكترونى',
      governorate: 'المحافظة',
      school_name: 'المدرسة',
      verification_document: 'صورة البطاقة او شهادة الميلاد',
    },
    post: {
      body: 'المحتوى',
      file: 'الملف',
      image: 'الصورة',
    },
    question: {
      answer: 'الإجابة',
    },
    answer: {
      body: 'محتوى الإجابة',
    },
    comment: {
      body: 'التعليق',
      file: 'الملف',
      image: 'الصورة',
    },
    package: {
      pin_code: 'الكود',
    },
    messages: {
      title: 'عنوان الرسالة',
      body: 'محتوى الرسالة',
    },
  },
  errors: {
    something_went_wrong: 'حدث خطأ ما! برجاء المحاولة لاحقا',
    something_went_wrong_status: 'حدث خطأ ما! برجاء المحاولة لاحقا - ({code})',
    field_not_found: '{field} غير موجود.',
    too_many_attemps: 'لقد قمت بمحاولات كثيرة على فترات متقاربة ، برجاء المحاولة لاحقا.',
    not_found: 'الصفحة غير موجودة',
    unauthorized_action: 'غير مصرح لك بهذه المحاولة',
  },
  rules: {
    accepted: 'يجب قبول {field}.',
    active_url: '{field} لا يُمثّل رابطًا صحيحًا.',
    after: 'يجب على {field} أن يكون تاريخًا لاحقًا للتاريخ {date}.',
    after_or_equal: '{field} يجب أن يكون تاريخاً لاحقاً أو مطابقاً للتاريخ {date}.',
    alpha: 'يجب أن لا يحتوي {field} سوى على حروف.',
    alpha_dash: 'يجب أن لا يحتوي {field} سوى على حروف، أرقام ومطّات.',
    alpha_num: 'يجب أن يحتوي {field} على حروفٍ وأرقامٍ فقط.',
    array: 'يجب أن يكون {field} ًمصفوفة.',
    before: 'يجب على {field} أن يكون تاريخًا سابقًا للتاريخ {date}.',
    before_or_equal: '{field} يجب أن يكون تاريخا سابقا أو مطابقا للتاريخ {date}.',
    between: {
      numeric: 'يجب أن تكون قيمة {field} بين {min} و {max}.',
      file: 'يجب أن يكون حجم الملف {field} بين {min} و {max} كيلوبايت.',
      string: 'يجب أن يكون عدد حروف النّص {field} بين {min} و {max}.',
      array: 'يجب أن يحتوي {field} على عدد من العناصر بين {min} و {max}.',
    },
    boolean: 'يجب أن تكون قيمة {field} إما true أو false .',
    confirmed: 'حقل التأكيد غير مُطابق للحقل {field}.',
    date: '{field} ليس تاريخًا صحيحًا.',
    date_equals: 'يجب أن يكون {field} مطابقاً للتاريخ {date}.',
    date_format: 'لا يتوافق {field} مع الشكل {format}.',
    different: 'يجب أن يكون الحقلان {field} و {other} مُختلفين.',
    digits: 'يجب أن يحتوي {field} على {digits} رقمًا/أرقام.',
    digits_between: 'يجب أن يحتوي {field} بين {min} و {max} رقمًا/أرقام .',
    dimensions: 'الـ {field} يحتوي على أبعاد صورة غير صالحة.',
    distinct: 'للحقل {field} قيمة مُكرّرة.',
    email: 'يجب أن يكون {field} عنوان بريد إلكتروني صحيح البُنية.',
    ends_with: 'يجب أن ينتهي {field} بأحد القيم التالية: {values}',
    exists: 'القيمة المحددة {field} غير موجودة.',
    file: 'الـ {field} يجب أن يكون ملفا.',
    filled: '{field} إجباري.',
    gt: {
      numeric: 'يجب أن تكون قيمة {field} أكبر من {value}.',
      file: 'يجب أن يكون حجم الملف {field} أكبر من {value} كيلوبايت.',
      string: 'يجب أن يكون طول النّص {field} أكثر من {value} حروفٍ/حرفًا.',
      array: 'يجب أن يحتوي {field} على أكثر من {value} عناصر/عنصر.',
    },
    gte: {
      numeric: 'يجب أن تكون قيمة {field} مساوية أو أكبر من {value}.',
      file: 'يجب أن يكون حجم الملف {field} على الأقل {value} كيلوبايت.',
      string: 'يجب أن يكون طول النص {field} على الأقل {value} حروفٍ/حرفًا.',
      array: 'يجب أن يحتوي {field} على الأقل على {value} عُنصرًا/عناصر.',
    },
    image: 'يجب أن يكون {field} صورةً.',
    in: '{field} غير موجود.',
    in_array: '{field} غير موجود في {other}.',
    integer: 'يجب أن يكون {field} عددًا صحيحًا.',
    ip: 'يجب أن يكون {field} عنوان IP صحيحًا.',
    ipv4: 'يجب أن يكون {field} عنوان IPv4 صحيحًا.',
    ipv6: 'يجب أن يكون {field} عنوان IPv6 صحيحًا.',
    json: 'يجب أن يكون {field} نصًا من نوع JSON.',
    lt: {
      numeric: 'يجب أن تكون قيمة {field} أصغر من {value}.',
      file: 'يجب أن يكون حجم الملف {field} أصغر من {value} كيلوبايت.',
      string: 'يجب أن يكون طول النّص {field} أقل من {value} حروفٍ/حرفًا.',
      array: 'يجب أن يحتوي {field} على أقل من {value} عناصر/عنصر.',
    },
    lte: {
      numeric: 'يجب أن تكون قيمة {field} مساوية أو أصغر من {value}.',
      file: 'يجب أن لا يتجاوز حجم الملف {field} {value} كيلوبايت.',
      string: 'يجب أن لا يتجاوز طول النّص {field} {value} حروفٍ/حرفًا.',
      array: 'يجب أن لا يحتوي {field} على أكثر من {value} عناصر/عنصر.',
    },
    max: {
      numeric: 'يجب أن تكون قيمة {field} مساوية أو أصغر من {max}.',
      file: 'يجب أن لا يتجاوز حجم الملف {field} {max} كيلوبايت.',
      string: 'يجب أن لا يتجاوز طول النّص {field} {max} حروفٍ/حرفًا.',
      array: 'يجب أن لا يحتوي {field} على أكثر من {max} عناصر/عنصر.',
    },
    mimes: 'يجب أن يكون ملفًا من نوع : {values}.',
    mimetypes: 'يجب أن يكون ملفًا من نوع : {values}.',
    min: {
      numeric: 'يجب أن تكون قيمة {field} مساوية أو أكبر من {min}.',
      file: 'يجب أن يكون حجم الملف {field} على الأقل {min} كيلوبايت.',
      string: 'يجب أن يكون طول النص {field} على الأقل {min} حروفٍ/حرفًا.',
      array: 'يجب أن يحتوي {field} على الأقل على {min} عُنصرًا/عناصر.',
    },
    ne: {
      numeric: 'لا يمكن ان تكون قيمة {field} تساوي {value}',
    },
    not_in: 'العنصر {field} غير صحيح.',
    not_regex: 'صيغة {field} غير صحيحة.',
    numeric: 'يجب على {field} أن يكون رقمًا.',
    password: 'كلمة المرور غير صحيحة.',
    present: 'يجب تقديم {field}.',
    regex: 'صيغة {field} غير صحيحة.',
    required: '{field} مطلوب.',
    required_if: '{field} مطلوب في حال ما إذا كان {other} يساوي {value}.',
    required_unless: '{field} مطلوب في حال ما لم يكن {other} يساوي {values}.',
    required_with: '{field} مطلوب إذا توفّر {values}.',
    required_with_all: '{field} مطلوب إذا توفّر {values}.',
    required_without: '{field} مطلوب إذا لم يتوفّر {values}.',
    required_without_all: '{field} مطلوب إذا لم يتوفّر {values}.',
    same: 'يجب أن يتطابق {field} مع {other}.',
    different_password: 'الرقم السرى الجديد يجب الا يتطابق مع الرقم السرى القديم',
    size: {
      numeric: 'يجب أن تكون قيمة {field} مساوية لـ {size}.',
      file: 'يجب أن يكون حجم الملف {field} {size} كيلوبايت.',
      string: 'يجب أن يحتوي النص {field} على {size} حروفٍ/حرفًا بالضبط.',
      array: 'يجب أن يحتوي {field} على {size} عنصرٍ/عناصر بالضبط.',
    },
    starts_with: 'يجب أن يبدأ {field} بأحد القيم التالية: {values}',
    string: 'يجب أن يكون {field} نصًا.',
    timezone: 'يجب أن يكون {field} نطاقًا زمنيًا صحيحًا.',
    unique: 'قيمة {field} مُستخدمة من قبل.',
    uploaded: 'فشل في تحميل الـ {field}.',
    url: 'صيغة الرابط {field} غير صحيحة.',
    uuid: '{field} يجب أن يكون بصيغة UUID سليمة.',
  },
  profile: {
    personal_data: 'البيانات الشخصية',
    basic_information: 'البيانات الاساسية',
    change_password: 'تغير كلمة المرور',
    current_password: 'كلمة المرور الحالية',
    new_password: 'كلمة المرور الجديدة',
    confirmation_password: 'تأكيد كلمة المرور الجديدة',
    password_updated_successfully: 'تم تغير كلمة المرور بنجاح',
  },
  publishable: {
    status: 'الحالة',
    publish: 'نشر',
    unpublish: 'الغاء النشر',
    published: 'منشور',
    unpublished: 'غير منشور',
    published_at: 'تاريخ النشر',
    now: 'نعم',
    0: 'لا',
    later: 'لاحقا',
  },
  code_batches: {
    lookup: 'بحث عن الاكواد',
    search: 'ابحث باسم الدفعة',
    name: 'اسم الدفعة',
    is_printed: 'مطبوعة',
    not_printed: 'غير مطبوعة',
    count: 'عدد الاكواد',
    value: 'قيمة الكود',
    used_codes_count: 'عدد الاكواد المستخدمة',
    unused_codes_count: 'عدد الاكواد الغير مستخدمة',
    used_codes_total: 'اجمالي الاكواد المستخدمة',
    unused_codes_total: 'اجمالي الاكواد الغير مستخدمة',
    creator: 'بواسطة',
    new_title: 'اضافة دفعة اكواد',
    edit_title: 'تعديل دفعة الاكواد',
    export: 'تصدير',
    code_batch_added: 'تم اضافة دفعة الاكواد بنجاح',
    code_batch_updated: 'تم تعديل دفعة الاكواد بنجاح',
  },
  money: {
    currency: 'ج.م',
  },
  dropdowns: {
    cities: [
      { name: 'أسوان' },
      { name: 'أسيوط' },
      { name: 'الأقصر' },
      { name: 'الإسكندرية' },
      { name: 'الإسماعيلية' },
      { name: 'البحر الأحمر' },
      { name: 'البحيرة' },
      { name: 'الجيزة' },
      { name: 'الدقهلية' },
      { name: 'السويس' },
      { name: 'الشرقية' },
      { name: 'الغربية' },
      { name: 'الفيوم' },
      { name: 'القاهرة' },
      { name: 'القليوبية' },
      { name: 'المنوفية' },
      { name: 'المنيا' },
      { name: 'الوادي الجديد' },
      { name: 'بني سويف' },
      { name: 'بورسعيد' },
      { name: 'جنوب سيناء' },
      { name: 'دمياط' },
      { name: 'سوهاج' },
      { name: 'شمال سيناء' },
      { name: 'قنا' },
      { name: 'كفر الشيخ' },
      { name: 'مطروح' },
    ],
  },
}
