export const VIDEOS_LOADING = 'VIDEOS_LOADING'
export const VIDEOS_LOADED = 'VIDEOS_LOADED'
export const PLAYBACK_LOADING = 'PLAYBACK_LOADING'
export const PLAYBACK_LOADED = 'PLAYBACK_LOADED'
export const CURRENT_VIDEO_LOADING = 'CURRENT_VIDEO_LOADING'
export const CURRENT_VIDEO_LOADED = 'CURRENT_VIDEO_LOADED'

const defaultState = () => ({
  videosStatus: null,
  playbackStatus: null,
  videos: [],

  currentVideo: null,
  currentVideoStatus: null,
})

export const state = () => defaultState()

export const mutations = {
  SET_VIDEOS_STATUS(state, status) {
    state.videosStatus = status
  },
  SET_PLAYBACK_STATUS(state, status) {
    state.playbackStatus = status
  },
  SET_CURRENT_VIDEO_STATUS(state, status) {
    state.currentVideoStatus = status
  },
  SET_VIDEOS(state, videos) {
    state.videos = videos
  },
  SET_VIDEO_PLAYBACK(state, playback) {
    const videoIndex = state.videos.findIndex((video) => video.id === playback.video_id)
    const video = {
      ...state.videos[videoIndex],
      playback,
    }

    state.videos.splice(videoIndex, 1, video)
  },
  SET_CURRENT_VIDEO(state, video) {
    state.currentVideo = video
    state.currentVideoStatus = CURRENT_VIDEO_LOADED
  },
  RESET_VIDEOS(state) {
    state.videosStatus = null
    state.playbackStatus = null
    state.videos = []
  },
  RESET_CURRENT_VIDEO(state) {
    state.currentVideo = null
    state.currentVideoStatus = null
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  async fetchLessonVideos({ commit, dispatch }, lessonId) {
    commit('SET_VIDEOS_STATUS', VIDEOS_LOADING)
    try {
      const {
        data: { data: videos },
      } = await this.$axios.get(`/api/v1/lessons/${lessonId}/videos`, {
        params: {
          per_page: -1,
          include: 'prerequisiteAssessment.assessmentSessionSummary',
        },
      })

      commit(
        'SET_VIDEOS',
        videos.map((video) => ({ ...video, playback: { allowedViews: null, remainingViews: null, views: null } }))
      )
    } catch (error) {
    } finally {
      commit('SET_VIDEOS_STATUS', VIDEOS_LOADED)
    }
  },

  fetchVideosPlayback({ commit, state }, lessonId) {
    for (let index = 0; index < state.videos.length; index++) {
      const video = state.videos[index]
      this.$axios.get(`/api/v1/lessons/${lessonId}/videos/${video.id}/playback`).then(({ data: { data } }) => {
        commit('SET_VIDEO_PLAYBACK', data)
      })
    }
  },

  async fetchCurrentVideo({ commit }, { lessonId, videoId }) {
    commit('SET_CURRENT_VIDEO_STATUS', CURRENT_VIDEO_LOADING)
    try {
      const {
        data: { data: video },
      } = await this.$axios.get(`/api/v1/lessons/${lessonId}/videos/${videoId}`, {
        params: {
          include: 'prerequisiteAssessment',
        },
      })

      commit('SET_CURRENT_VIDEO', video)
    } catch (error) {
    } finally {
      commit('SET_CURRENT_VIDEO_STATUS', CURRENT_VIDEO_LOADED)
    }
  },
}

export const getters = {
  videosLoading: (state) => state.videosStatus === VIDEOS_LOADING,
  videosLoaded: (state) => state.videosStatus === VIDEOS_LOADED,

  currentVideoLoading: (state) => state.currentVideoStatus === CURRENT_VIDEO_LOADING,
  currentVideoLoaded: (state) => state.currentVideoStatus === CURRENT_VIDEO_LOADED,

  playbackLoading: (state) => state.videos.some((video) => video.playback.allowedViews === null),
  playbackLoaded: (state) => state.videos.every((video) => video.playback.allowedViews !== null),
}
