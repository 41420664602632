export const state = () => ({
  isLoading: false,
  showTermsMobileView: false,

  lightboxImages: [],
})

export const mutations = {
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_LIGHTBOX_IMAGES(state, images) {
    state.lightboxImages = images
  },
  SET_SHOW_TERMS_MOBILE_VIEWS(state, value) {
    state.showTermsMobileView = value
  },
  HIDE_LIGHTBOX(state) {
    state.lightboxImages = []
  },
}

export const actions = {}

export const getters = {
  isLightboxVisible: (state) => Boolean(state.lightboxImages.length),
}
