export const constructCourseFormData = (courseInfo) => {
  const formData = new FormData()
  formData.append('title', courseInfo.title)
  formData.append('summary', courseInfo.summary)
  formData.append('details', courseInfo.details)
  formData.append('teacher_id', courseInfo.teacher_id)
  formData.append('rank', courseInfo.rank)
  formData.append('thumbnail', courseInfo.thumbnail)

  for (const assistant of courseInfo.assistants) {
    formData.append('assistants[]', assistant)
  }

  for (const accountant of courseInfo.accountants) {
    formData.append('accountants[]', accountant)
  }

  for (const supervisor of courseInfo.supervisors) {
    formData.append('supervisors[]', supervisor)
  }

  if (courseInfo.category_id) {
    formData.append('category_id', courseInfo.category_id)
  }

  if (courseInfo.reservation_info) {
    formData.append('reservation_info', courseInfo.reservation_info)
  }

  return formData
}

export const formatDateTime = (value, locale = 'en') => {
  return value.locale(locale).format('D/M/YYYY, H:m A')
}
