export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING'
export const NOTIFICATIONS_LOADED = 'NOTIFICATIONS_LOADED'

const defaultState = () => ({
  unread: [],
  all: [],
  unreadStatus: null,

  unreadCount: 0,
  currentPage: 0,
  totalPages: null,
})

export const state = () => defaultState()

export const mutations = {
  SET_UNREAD_STATUS(state, status) {
    state.unreadStatus = status
  },
  SET_UNREAD(state, notifications) {
    state.unread = notifications
  },
  SET_ALL(state, notifications) {
    state.all = notifications
  },

  SET_TOTAL_PAGES(state, total) {
    state.totalPages = total
  },

  SET_CURRENT_PAGE(state, page) {
    state.currentPage = page
  },

  SET_UNREAD_COUNT(state, count) {
    state.unreadCount = count
  },
  DECREMENT_UNREAD_COUNT(state) {
    state.unreadCount = Math.max(state.unreadCount - 1, 0)
  },

  NEW_UNREAD_NOTIFICATION(state, notification) {
    state.unread = [notification, ...state.unread]
    state.all = [notification, ...state.all]
    state.unreadCount += 1
  },

  RESET_UNREAD(state) {
    state.unread = []
    state.unreadStatus = null
    state.unreadCount = 0
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  async fetchUnread({ commit }) {
    commit('SET_UNREAD_STATUS', NOTIFICATIONS_LOADING)
    try {
      const {
        data: { data: unread, meta },
      } = await this.$axios.get(`/api/v1/notifications/unread`)

      commit('SET_UNREAD_COUNT', meta.pagination.total)
      commit('SET_UNREAD', unread)
    } catch (error) {
    } finally {
      commit('SET_UNREAD_STATUS', NOTIFICATIONS_LOADED)
    }
  },

  async fetchAll({ commit, state }) {
    try {
      const {
        data: {
          data: notifications,
          meta: {
            pagination: { total_pages: totalPages, current_page: currentPage },
          },
        },
      } = await this.$axios.get('/api/v1/notifications', {
        params: {
          page: state.currentPage + 1,
        },
      })
      commit('SET_ALL', notifications)
      commit('SET_TOTAL_PAGES', totalPages)
      commit('SET_CURRENT_PAGE', currentPage)
    } catch (error) {}
  },
}

export const getters = {
  unreadLoading: (state) => state.unreadStatus === NOTIFICATIONS_LOADING,
  unreadLoaded: (state) => state.unreadStatus === NOTIFICATIONS_LOADED,
}
