export const CATEGORIES_LOADING = 'CATEGORIES_LOADING'
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED'

const defaultState = () => ({
  categoriesStatus: null,
  categories: [],
})

export const state = () => defaultState()

export const mutations = {
  SET_CATEGORIES_STATUS(state, status) {
    state.categoriesStatus = status
  },
  SET_CATEGORIES(state, categories) {
    state.categories = [...categories, { id: null }]
  },
  RESET_CATEGORIES(state) {
    state.categories = []
    state.categoriesStatus = null
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  async fetchCourseLessonCategories({ commit }, courseId) {
    commit('SET_CATEGORIES_STATUS', CATEGORIES_LOADING)

    try {
      const { data } = await this.$axios.$get(`/api/v1/courses/${courseId}/lessonCategories`)

      commit('SET_CATEGORIES', data)
    } catch (error) {
    } finally {
      commit('SET_CATEGORIES_STATUS', CATEGORIES_LOADED)
    }
  },
}

export const getters = {
  lessonsCategoriesLoading: (state) => state.categoriesStatus === CATEGORIES_LOADING,
  lessonsCategoriesLoaded: (state) => state.categoriesStatus === CATEGORIES_LOADED,
}
