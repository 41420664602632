export const CURRENT_DISCUSSION_LOADING = 'CURRENT_DISCUSSION_LOADING'
export const CURRENT_DISCUSSION_LOADED = 'CURRENT_DISCUSSION_LOADED'

const defaultState = () => ({
  currentDiscussion: null,
  currentDiscussionStatus: null,
})

export const state = () => defaultState()

export const mutations = {
  SET_CURRENT_DISCUSSION_STATUS(state, status) {
    state.currentDiscussionStatus = status
  },
  SET_CURRENT_DISCUSSION(state, discussion) {
    state.currentDiscussion = discussion
  },
  RESET_CURRENT_DISCUSSION(state) {
    state.currentDiscussion = null
    state.currentDiscussionStatus = null
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  async fetchCurrentDiscussion({ commit }, { lessonId, discussionId }) {
    commit('SET_CURRENT_DISCUSSION_STATUS', CURRENT_DISCUSSION_LOADING)
    try {
      const {
        data: { data: discussion },
      } = await this.$axios.get(`/api/v1/lessons/${lessonId}/posts/${discussionId}`)

      commit('SET_CURRENT_DISCUSSION', discussion)
    } catch (error) {
    } finally {
      commit('SET_CURRENT_DISCUSSION_STATUS', CURRENT_DISCUSSION_LOADED)
    }
  },
}

export const getters = {
  currentDiscussionLoading: (state) => state.currentDiscussionStatus === CURRENT_DISCUSSION_LOADING,
  currentDiscussionLoaded: (state) => state.currentDiscussionStatus === CURRENT_DISCUSSION_LOADED,

  getCurrentDiscussion: (state, _getters, _rootState, rootGetters) => {
    return {
      ...state.currentDiscussion,
      reply: {
        ...state.currentDiscussion.reply.data,
        last_editor: rootGetters.getUser(state.currentDiscussion.reply.data.last_editor),
      },
      student: rootGetters.getUser(state.currentDiscussion.student),
    }
  },
}
